import { SET_TASKS, SET_TASKS_ERROR, SET_TASKS_MODAL_STATE, TURN_ON_TASKS_LOADER } from '../constants';

const initialState = {
  tasks: [],
  pagination: { currentPage: 0, totalPages: 0 },
  modalIsOpen: false,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TASKS:
      return {
        ...state,
        loading: false,
        tasks: action.payload.tasks,
        pagination: action.payload.pagination,
      };

    case SET_TASKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TURN_ON_TASKS_LOADER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SET_TASKS_MODAL_STATE:
      return {
        ...state,
        modalIsOpen: action.payload,
      };

    default:
      return state;
  }
};
