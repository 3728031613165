import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Tasks.module.scss';
import Header from '../../components/Header/Header';
import EditQuestionPopup from '../../components/EditQuestionPopup/EditQuestionPopup';
import moment from 'moment';
import { deletePopupOpen } from '../../store/actions';
import ListOfQuestionsPopup from '../../components/ListOfQuestionsPopup/ListOfQuestionsPopup';
import { setTasks, setTasksModalState } from '../../store/actions/Tasks';
import Spinner from '../../components/Spinner/Spinner';
import { getSecondsByTime } from '../../constants/globalFunctions';
import { LIST_OF_LEVELS } from '../../constants/globalConstants';

const Tasks = () => {
  const { tasks, pagination, loading, modalIsOpen } = useSelector(({ tasks }) => tasks);
  const { creationSuccess } = useSelector(({ question }) => question);
  const dispatch = useDispatch();

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [rating, setRating] = useState('');
  const [defaultRating, setDefaultRating] = useState('');
  const [level, setLevel] = useState('');
  const [page, setPage] = useState(0);
  const [type, setType] = useState('');
  const [topic, setTopic] = useState('');
  const [category, setCategory] = useState('');
  const [id, setId] = useState('');

  const currentLevel = useMemo(() => {
    const lvl = LIST_OF_LEVELS.find(({ value }) => value === level.toUpperCase());

    return lvl ? lvl.value : null;
  }, [level]);

  const headers = useMemo(
    () => [
      { id: 0, name: 'ID', value: id, onChange: setId },
      { id: 1, name: 'Date', value: null, onChange: null },
      { id: 2, name: 'Category', value: category, onChange: setCategory },
      { id: 3, name: 'Type', value: type, onChange: setType },
      { id: 4, name: 'Topic', value: topic, onChange: setTopic },
      { id: 5, name: 'Default rating', value: defaultRating, onChange: setDefaultRating },
      { id: 6, name: 'Rating', value: rating, onChange: setRating },
      { id: 7, name: 'Level', value: level, onChange: setLevel },
      { id: 8, name: 'Time', value: null, onChange: null },
      { id: 9, name: 'Action', value: null, onChange: null },
    ],
    [rating, defaultRating, level, id, type, category, topic],
  );

  const handleOpenModal = question => {
    setCurrentQuestion(question);
    dispatch(setTasksModalState(true));
  };

  const handleDeleteSentence = (e, id) => {
    e.stopPropagation();

    dispatch(deletePopupOpen(id, {}));
  };

  const onPageChange = useCallback(
    page => {
      setPage(page);
      dispatch(setTasks(page, id, rating, defaultRating, level || null, category, type, topic));
    },
    [dispatch, id, rating, defaultRating, level, category, type, topic],
  );

  const handleCloseModal = () => {
    dispatch(setTasksModalState(false));
    setCurrentQuestion(null);
  };

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      dispatch(setTasks(0, id, rating, defaultRating, currentLevel || null, category, type, topic));
    },
    [dispatch, id, rating, defaultRating, currentLevel, category, type, topic],
  );

  useEffect(() => {
    dispatch(setTasks());
  }, [dispatch]);

  useEffect(() => {
    if (creationSuccess) {
      dispatch(setTasks(page, id, rating, defaultRating, currentLevel, category, type, topic));
    }
  }, [dispatch, page, id, rating, defaultRating, currentLevel, creationSuccess, category, type, topic]);

  return (
    <div>
      <ListOfQuestionsPopup />
      <Header />
      <div className={styles.questions}>
        {Boolean(currentQuestion) && <EditQuestionPopup question={currentQuestion} modalIsOpen={modalIsOpen} handleCloseModal={handleCloseModal} />}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <form onSubmit={onSubmit}>
              <ul className={styles.questions__headers}>
                {headers.map((title, index, array) => {
                  const isLastItem = index === array.length - 1;

                  return (
                    <li key={title.id} className={styles.questions__title}>
                      <span>{title.name}</span>
                      {title.onChange !== null && (
                        <input type='text' value={title.value} className={styles.input} onChange={({ target }) => title.onChange(target.value)} />
                      )}
                      {isLastItem && (
                        <button type='submit' className={styles.button}>
                          Search
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>
            </form>
            <ul className={styles.questions__list}>
              {tasks.map(question => {
                const date = moment(question.createdAt).format('DD/MM/YY');
                const category = `${question.topicType[0]}${question.topicType.slice(1).toLowerCase()}`;
                const time = getSecondsByTime(question.recommendedTime);

                return (
                  <li key={question.id} className={styles.questions__item}>
                    <section onClick={() => handleOpenModal(question)}>
                      <ul className={styles.question__info}>
                        <li className={styles.question__infoItem}>{question.id}</li>
                        <li className={styles.question__infoItem}>{date}</li>
                        <li className={styles.question__infoItem}>{category}</li>
                        <li className={styles.question__infoItem}>{question.questionTypeName}</li>
                        <li className={styles.question__infoItem}>{question.topicName}</li>
                        <li className={styles.question__infoItem}>{question.defaultRating}</li>
                        <li className={styles.question__infoItem}>{question.rating}</li>
                        <li className={styles.question__infoItem}>{question.level}</li>
                        <li className={styles.question__infoItem}>{time}s</li>
                        <li className={styles.question__infoItem}>
                          <button className={styles.question__notApproveButton} onClick={e => handleDeleteSentence(e, question.id)} />
                        </li>
                      </ul>
                    </section>
                  </li>
                );
              })}
            </ul>

            <div className={styles.pagination__wrap}>
              <ReactPaginate
                previousLabel='<'
                nextLabel='>'
                pageCount={pagination.totalPages}
                forcePage={pagination.currentPage}
                onPageChange={({ selected }) => onPageChange(selected)}
                containerClassName={styles.pagination__content}
                pageClassName={styles.pagination__button}
                pageLinkClassName={styles.pagination__link}
                previousLinkClassName={styles.pagination__button}
                nextLinkClassName={styles.pagination__button}
                disabledClassName={styles.pagination__button_disabled}
                activeClassName={`${styles.pagination__button} ${styles.pagination__button_active}`}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tasks;
