import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from '../../components/Header/Header';
import Levels from './Levels/Levels';
import Categories from './Categories/Categories';
import Words from './Words/Words';

const Dictionary = () => {
  const AdminRoute = ({ component: Component, token, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
            }}
          />
        )
      }
    />
  );

  return (
    <div>
      <Header />
      <Switch>
        <AdminRoute path='/dictionary/levels' exact>
          <Levels />
        </AdminRoute>
        <AdminRoute path='/dictionary/levels/:levelId/categories' exact>
          <Categories />
        </AdminRoute>
        <AdminRoute path='/dictionary/levels/:levelId/categories/:categoryId/words' exact>
          <Words />
        </AdminRoute>
      </Switch>
    </div>
  );
};

export default Dictionary;
