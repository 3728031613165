import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import imageCompression from 'browser-image-compression';

import styles from './VocabularySpelling.module.scss';
import { BASE_URL, LIST_OF_LEVELS, RATING_MAX, RATING_MIN } from '../../../../constants/globalConstants';
import FKMediaUploader from '../../../../components/FKMediaUploader/FKMediaUploader';
import FKInput from '../../../../components/FKInput/FKInput';
import SquareSelect from '../../../../components/SquareSelect/SquareSelect';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import AddButton from '../../../../components/AddButton/AddButton';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import { editPopupOpen, popupOpen } from '../../../../store/actions';
import EditPopupButtons from '../../../../components/EditPopupButtons/EditPopupButtons';
import DuplicateSentenceSearch from '../../../../components/DuplicateSentenceSearch/DuplicateSentenceSearch';
import FKSelect from '../../../../components/FKSelect/FKSelect';
import { getFormattedTime } from '../../../../constants/globalFunctions';

const VocabularySpelling = ({ question, handleClosePopup = () => {} }) => {
  const { levels } = useSelector(({ question }) => question);
  const { options } = useSelector(({ asideMenu }) => asideMenu);
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);
  const INPUT_WIDTH = 200 + Boolean(!isEditPage) * 50;
  const { pathname } = useLocation();
  const hideDuplicateSentenceSearch = pathname !== '/questions' && isEditPage;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      picture: Yup.mixed().when('isEditPage', {
        is: false,
        then: Yup.mixed().required(),
      }),
      answers: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .max(20)
            .required(),
        }),
      ),
      rating: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .required(),
      defaultRating: Yup.number()
        .test('', function(value) {
          const level = this.parent.level?.value;

          if (level) {
            return value >= levels[level].min && value <= levels[level].max;
          }

          return false;
        })
        .required(),
      time: Yup.number()
        .min(3)
        .max(180)
        .required(),
      level: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(),
    });
  }, [levels]);

  const initialState = {
    isEditPage: false,
    sentences: ['Which word is spelled correctly?'],
    picture: null,
    answers: [
      { name: '', id: uuid(), ordinalRank: 1 },
      { name: '', id: uuid(), ordinalRank: null },
    ],
    rating: 0,
    defaultRating: 0,
    time: 0,
    level: null,
  };

  const listOfTopics = useMemo(() => {
    if (question) {
      const { topics } = options.find(({ topicType }) => topicType === question.topicType);

      return topics.map(({ name }) => ({ label: name, value: name }));
    }

    return [];
  }, [options, question]);

  const handleSubmit = useCallback(
    async (values, { resetForm, setSubmitting }) => {
      try {
        const trimAnswers = values.answers.map(answer => ({ ...answer, name: answer.name.trim() }));
        const options = {
          maxSizeMB: 100,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        if (isEditPage) {
          const { id } = question;
          const requestData = {
            ...values,
            answers: trimAnswers,
            file: values.picture,
            level: values.level.value,
            recommendedTime: getFormattedTime(values.time),
            ...(isEditPage && {
              topicName: values.topicName.value,
              topicType: question.topicType,
            }),
          };

          dispatch(editPopupOpen(id, requestData));
        } else {
          const compressedFile = await imageCompression(values.picture, options);
          const requestData = {
            ...values,
            answers: trimAnswers,
            file: compressedFile,
            level: values.level.value,
            recommendedTime: getFormattedTime(values.time),
            ...(isEditPage && {
              topicName: values.topicName.value,
              topicType: question.topicType,
            }),
          };

          dispatch(popupOpen(requestData, resetForm));
        }

        setSubmitting(false);
      } catch (error) {
        alert(error);
      }
    },
    [dispatch, isEditPage, question],
  );

  return (
    <Formik initialValues={question || initialState} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>
      {({ values, setFieldValue }) => {
        const fileUrl = `${BASE_URL}/file/${question?.fileId}`;
        const disabledAddBtn = values.answers.length >= 6;
        const previewImg = values.picture && URL.createObjectURL(values.picture);
        const answerWithSolution = values.answers
          .filter(({ ordinalRank }) => ordinalRank)
          .sort((a, b) => (a.ordinalRank > b.ordinalRank ? 1 : a.ordinalRank < b.ordinalRank ? -1 : 0));
        const selectOptionList = [1];
        const levelRatingRange = values.level ? levels[values.level.value] : { min: 0, max: 0 };

        const handleChangeAnswer = (i, answer) => {
          values.answers.forEach((item, index) => {
            if (item.ordinalRank === answer) {
              setFieldValue(`answers.${index}.ordinalRank`, null);
            }
          });

          setFieldValue(`answers.${i}.ordinalRank`, answer);
        };

        return (
          <Form>
            {!hideDuplicateSentenceSearch && <DuplicateSentenceSearch type='PICTURE_SPELLING_VOCABULARY' />}
            {!isEditPage && (
              <section className={styles.questionWrapper}>
                <h3 className={styles.title}>File (1 photo with points/arrows jpg, png):</h3>
                <Field name='picture' component={FKMediaUploader} width='50%' />
              </section>
            )}
            <section className={styles.answerWrapper}>
              <h3 className={styles.title}>Answers</h3>
              <FieldArray name='answers'>
                {arrayHelpers => (
                  <div>
                    <div className={styles.fieldsContainer}>
                      {values.answers.map((answer, i) => {
                        const disabledDeleteBtn = values.answers.length < 3 || answer.ordinalRank;

                        return (
                          <div className={styles.answerContainer} key={answer.id}>
                            <div className={styles.answer}>
                              <Field key={answer.id} name={`answers.${i}.name`} component={FKInput} placeholder='Enter your answer' width='100%' />
                              <SquareSelect
                                optionsList={selectOptionList}
                                handleChange={(_, index) => handleChangeAnswer(i, index)}
                                position={answer.ordinalRank}
                              />
                              <DeleteButton disabled={disabledDeleteBtn} onClick={() => arrayHelpers.remove(i)} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className={styles.buttonSpace}>
                      <AddButton disabled={disabledAddBtn} onClick={() => arrayHelpers.push({ name: '', id: uuid() })} />
                    </div>
                  </div>
                )}
              </FieldArray>
            </section>
            <section>
              <section className={styles.additionalSettings}>
                {isEditPage && (
                  <div style={{ marginRight: 50 }}>
                    <h2>Topic</h2>
                    <Field
                      name='topicName'
                      component={FKSelect}
                      placeholder='Select your topic'
                      height={44}
                      width={INPUT_WIDTH}
                      options={listOfTopics}
                    />
                  </div>
                )}
                <div>
                  <h2>Time (s)</h2>
                  <Field name='time' component={FKInput} placeholder='Enter your time' height={44} width={INPUT_WIDTH} type='number' />
                </div>
                <div style={{ marginLeft: 50 }}>
                  <h2>Level</h2>
                  <Field name='level' component={FKSelect} placeholder='Select your level' height={44} width={INPUT_WIDTH} options={LIST_OF_LEVELS} />
                </div>
                <div style={{ marginLeft: 50, opacity: values.level ? 1 : 0.5 }}>
                  <h2>
                    DefaultRating ({levelRatingRange.min} - {levelRatingRange.max})
                  </h2>
                  <Field
                    name='defaultRating'
                    component={FKInput}
                    placeholder='Enter your default rating'
                    height={44}
                    width={INPUT_WIDTH}
                    type='number'
                  />
                </div>
              </section>
              <h3 className={styles.title}>Preview</h3>
              <div className={styles.previewContainer}>
                <div className={styles.pictureContainer}>
                  {values.picture ? (
                    <img src={previewImg} alt='Preview img' style={{ borderRadius: 10 }} height={200} width={200} />
                  ) : isEditPage ? (
                    <img src={fileUrl} alt='Preview img' height={200} width={200} />
                  ) : (
                    <div className={styles.emptyImgContainer}>
                      <img src={require('../../../../img/photo-camera.svg')} alt='Empty field' height={50} width={50} />
                    </div>
                  )}
                </div>
                <div className={styles.preview}>
                  <span className={styles.previewQuestionContainer}>Which word is spelled correctly?</span>
                  <div className={styles.previewAnswerWrapper}>
                    {answerWithSolution.map(({ name, id }) => (
                      <div className={styles.previewAnswerContainer} key={id}>
                        {name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            {isEditPage ? <EditPopupButtons handleClose={handleClosePopup} /> : <SaveButton type='submit' />}
          </Form>
        );
      }}
    </Formik>
  );
};

export default VocabularySpelling;
