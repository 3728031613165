import { SET_STATISTICS, SET_STATISTICS_ERROR, SET_ACTIVATE_LOADER, SET_STATISTICS_RATING, SET_STATISTICS_LEVEL } from '../constants/';

const initialState = {
  statistics: [],
  minRating: null,
  maxRating: null,
  level: { label: 'ALL', value: null },
  loading: true,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_STATISTICS:
      return {
        ...state,
        statistics: payload,
        loading: false,
        error: null,
      };

    case SET_STATISTICS_RATING:
      return {
        ...state,
        minRating: payload.minRating,
        maxRating: payload.maxRating,
      };

    case SET_STATISTICS_LEVEL:
      return {
        ...state,
        level: payload,
      };

    case SET_ACTIVATE_LOADER:
      return {
        ...state,
        loading: true,
        error: payload,
      };

    case SET_STATISTICS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
