import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AxiosConfig from '../../src/AxiosConfig';
import SignIn from '../pages/SignIn/SignIn';
import AddQuestion from '../pages/AddQuestion/AddQuestion';
import ListOfQuestions from '../pages/ListOfQuestions/ListOfQuestions';
import Statistics from '../pages/Statistics/Statistics';
import Dictionary from '../pages/Dictionary/Dictionary';
import Tasks from '../pages/Tasks/Tasks';
import { getAsideMenuOptions, setQuestionLevels } from '../store/actions';

const AdminRoute = ({ component: Component, token, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
          }}
        />
      )
    }
  />
);

const Routes = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(({ auth }) => auth);

  useEffect(() => {
    dispatch(setQuestionLevels());
    dispatch(getAsideMenuOptions());
  }, [dispatch]);

  AxiosConfig();

  return (
    <Switch>
      <Route path='/signin' component={SignIn} />
      <AdminRoute path='/tasks' token={token} component={Tasks} />
      <AdminRoute path='/dictionary/levels' token={token} component={Dictionary} />
      <AdminRoute path='/questions' token={token} component={ListOfQuestions} />
      <AdminRoute path='/statistics' token={token} component={Statistics} />
      <AdminRoute path='/' token={token} component={AddQuestion} />
      <Redirect to='/signin' />
    </Switch>
  );
};

export default Routes;
