import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import styles from './ListeningTrueFalse.module.scss';
import { BASE_URL, RATING_MAX, RATING_MIN } from '../../../../constants/globalConstants';
import FKMediaUploader from '../../../../components/FKMediaUploader/FKMediaUploader';
import FKInput from '../../../../components/FKInput/FKInput';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import FKSelect from '../../../../components/FKSelect/FKSelect';
import { editPopupOpen, popupOpen } from '../../../../store/actions';
import EditPopupButtons from '../../../../components/EditPopupButtons/EditPopupButtons';

const ListeningTrueFalse = ({ question, handleClosePopup = () => {} }) => {
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      audio: Yup.mixed().when('isEditPage', {
        is: false,
        then: Yup.mixed().required(),
      }),
      sentences: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(),
        }),
      ),
      answers: Yup.array().of(
        Yup.object().shape({
          name: Yup.object().required(),
        }),
      ),
      rating: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .required(),
    });
  }, []);

  const initialState = {
    isEditPage: false,
    audio: null,
    sentences: [{ name: '', id: uuid() }],
    answers: [{ name: '', id: uuid(), ordinalRank: null }],
    rating: '',
  };

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const requestData = {
        sentences: [values.sentences[0].name.trim()],
        answers: [
          {
            ...values.answers[0],
            name: values.answers[0].name.value,
          },
        ],
        rating: values.rating,
        file: values.audio,
      };

      if (isEditPage) {
        const { id } = question;

        dispatch(editPopupOpen(id, requestData));
      } else {
        dispatch(popupOpen(requestData, resetForm));
      }

      setSubmitting(false);
    },
    [dispatch, isEditPage, question],
  );

  return (
    <Formik initialValues={question || initialState} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>
      {({ values }) => {
        const fileUrl = `${BASE_URL}/file/${question?.fileId}`;
        const preview = values.audio && URL.createObjectURL(values.audio);
        const audioPreview = isEditPage ? fileUrl : preview;
        const answerOptions = [
          { value: 'Not mentioned', label: 'Not mentioned' },
          { value: 'True', label: 'True' },
          { value: 'False', label: 'False' },
        ];

        return (
          <Form>
            {!isEditPage && (
              <section className={styles.questionWrapper}>
                <h3 className={styles.title}>File (audio):</h3>
                <Field name='audio' accept='audio/*' maxSize={100000000} component={FKMediaUploader} width='50%' />
              </section>
            )}
            <section className={styles.questionWrapper}>
              <div className={styles.questionTitleContainer}>
                <h3 className={styles.title}>Question</h3>
              </div>
              <FieldArray name='sentences'>
                {() =>
                  values.sentences.map((question, i) => {
                    return <Field key={question.id} name={`sentences.${i}.name`} component={FKInput} placeholder='Enter your question' width='60%' />;
                  })
                }
              </FieldArray>
            </section>
            <section className={styles.answerContainer}>
              <h3 className={styles.title}>Answer</h3>
              <Field name={`answers.${0}.name`} component={FKSelect} options={answerOptions} placeholder='Enter your answer' width='40%' />
            </section>
            <section className={styles.ratingContainer}>
              <h3 className={styles.title}>Approximate rating</h3>
              <Field name='rating' type='number' component={FKInput} placeholder='Enter question rating' width='25%' />
            </section>
            <section>
              <h3 className={styles.title}>Preview</h3>
              <div className={styles.previewContainer}>
                <div className={styles.audioContainer}>
                  <audio controls src={audioPreview}>
                    <p>
                      Your browser does not support HTML5 audio.
                      <a href={audioPreview}>{values.audio?.name}</a>
                    </p>
                  </audio>
                </div>
                <div className={styles.preview}>
                  <div className={styles.solutionQuestion}>{values.sentences[0].name}</div>
                  <div className={styles.solutionAnswer}>{values.answers[0].name.value}</div>
                </div>
              </div>
            </section>
            {isEditPage ? <EditPopupButtons handleClose={handleClosePopup} /> : <SaveButton type='submit' />}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ListeningTrueFalse;
