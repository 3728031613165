import {
  GET_LIST_OF_QUESTIONS_REQUEST,
  GET_LIST_OF_QUESTIONS_SUCCESS,
  GET_LIST_OF_QUESTIONS_FAILURE,
  EDIT_QUESTION_VALUE,
  EDIT_QUESTION_RATING,
  EDIT_QUESTION_POSITION,
  RUN_VALIDATE,
  SET_LIST_OF_QUESTIONS_PAGINATION,
  OPEN_EDIT_MODAL,
  CLOSE_EDIT_MODAL,
  GET_FILE,
} from '../constants/';

const INIT_STATE = {
  data: {},
  file: {},
  currentQuestion: {},
  modalIsOpen: false,
  pagination: { currentPage: 0, totalPages: 0 },
  loading: false,
  error: null,
  runValidate: false,
  validateQuestion: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_OF_QUESTIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_LIST_OF_QUESTIONS_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case SET_LIST_OF_QUESTIONS_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload } };
    case GET_LIST_OF_QUESTIONS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case EDIT_QUESTION_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item => {
            if (item.id === action.payload.questionId) {
              return {
                ...item,
                [action.payload.partType]: item[action.payload.partType].map(part => {
                  if (part.id === action.payload.partId) {
                    return {
                      ...part,
                      [action.payload.name]: action.payload.value,
                    };
                  }

                  return part;
                }),
              };
            }

            return item;
          }),
        },
      };
    case EDIT_QUESTION_RATING:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                rating: action.payload.value,
              };
            }

            return item;
          }),
        },
      };
    case EDIT_QUESTION_POSITION:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item => {
            if (item.id === action.payload.questionId) {
              return {
                ...item,
                answers: item.answers.map(part => {
                  if (part.id === action.payload.nextAnswerId) {
                    return {
                      ...part,
                      position: action.payload.prevAnswerPosition,
                    };
                  }

                  if (part.id === action.payload.prevAnswerId) {
                    return {
                      ...part,
                      position: action.payload.nextAnswerPosition,
                    };
                  }

                  return part;
                }),
              };
            }

            return item;
          }),
        },
      };
    case RUN_VALIDATE:
      return { ...state, ...action.payload };

    case GET_FILE:
      return { ...state, file: action.payload };

    case OPEN_EDIT_MODAL:
      return {
        ...state,
        modalIsOpen: true,
        currentQuestion: action.payload,
      };

    case CLOSE_EDIT_MODAL:
      return {
        ...state,
        modalIsOpen: false,
        currentQuestion: {},
      };

    default:
      return state;
  }
};
