import React from 'react';
import styles from './SaveButton.module.scss';

const SaveButton = ({ saveSentence = () => {}, type = 'button', title = 'Save' }) => {
  return (
    <button
      type={type}
      className={styles.saveButton}
      onClick={saveSentence}
    >
      {title}
    </button>
  );
}

export default SaveButton;
