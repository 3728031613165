import React from 'react';
import { Route, Switch } from 'react-router-dom';

import styles from './AddQuestionContainer.module.scss';
import Aside from '../Aside/Aside';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import NewSubstitution from '../../pages/AddQuestion/Grammar/NewSubstitution/NewSubstitution';
import NewCorrectMistake from '../../pages/AddQuestion/Grammar/NewCorrectMistake/NewCorrectMistake';
import NewWordOrder from '../../pages/AddQuestion/Grammar/NewWordOrder/NewWordOrder';
// import VocabularyDefinition from '../../pages/AddQuestion/Vocabulary/VocabularyDefinition/VocabularyDefinition';
// import VocabularyGapFill from '../../pages/AddQuestion/Vocabulary/VocabularyGapFill/VocabularyGapFill';
import GrammarGroup from '../../pages/AddQuestion/Grammar/GrammarGroup/GrammarGroup';
// import VocabularyPictureArrows from '../../pages/AddQuestion/Vocabulary/VocabularyPictureArrows/VocabularyPictureArrows';
import VocabularyPictureMeaning from '../../pages/AddQuestion/Vocabulary/VocabularyPictureMeaning/VocabularyPictureMeaning';
// import VocabularySpelling from '../../pages/AddQuestion/Vocabulary/VocabularySpelling/VocabularySpelling';
import ListeningMultipleChoice from '../../pages/AddQuestion/Listening/ListeningMultipleChoice/ListeningMultipleChoice';
import ListeningWordOrder from '../../pages/AddQuestion/Listening/ListeningWordOrder/ListeningWordOrder';
import ListeningTrueFalse from '../../pages/AddQuestion/Listening/ListeningTrueFalse/ListeningTrueFalse';
import ListeningVideo from '../../pages/AddQuestion/Listening/ListeningVideo/ListeningVideo';
import ListeningLetterOrder from '../../pages/AddQuestion/Listening/ListeningLetterOrder/ListeningLetterOrder';

const AddQuestionContent = ({ match }) => (
  <div className={styles.pageContainer}>
    <Aside />
    <div className={styles.contentContainer}>
      <Breadcrumbs />
      <Switch>
        {/* GRAMMAR ROUTES START */}
        <Route path={`${match.url}grammar/group`} exact>
          <GrammarGroup />
        </Route>
        <Route path={`${match.url}grammar/substitution`} exact>
          <NewSubstitution />
        </Route>
        <Route path={`${match.url}grammar/correctamistake`} exact>
          <NewCorrectMistake />
        </Route>
        <Route path={`${match.url}grammar/wordorder`} exact>
          <NewWordOrder />
        </Route>
        {/* GRAMMAR ROUTES END */}

        {/* VOCABULARY ROUTES START */}
        {/*<Route path={`${match.url}vocabulary/definition`} exact>*/}
        {/*  <VocabularyDefinition />*/}
        {/*</Route>*/}
        {/*<Route path={`${match.url}vocabulary/gapfill`} exact>*/}
        {/*  <VocabularyGapFill />*/}
        {/*</Route>*/}
        {/*<Route path={`${match.url}vocabulary/picturearrows`} exact>*/}
        {/*  <VocabularyPictureArrows />*/}
        {/*</Route>*/}
        <Route path={`${match.url}vocabulary/picture`} exact>
          <VocabularyPictureMeaning />
        </Route>
        {/*<Route path={`${match.url}vocabulary/spelling`} exact>*/}
        {/*  <VocabularySpelling />*/}
        {/*</Route>*/}
        {/* VOCABULARY ROUTES END */}

        {/* LISTENING ROUTES START */}
        <Route path={`${match.url}listening/multiplechoice`} exact>
          <ListeningMultipleChoice />
        </Route>
        <Route path={`${match.url}listening/gapfill`} exact>
          <ListeningMultipleChoice />
        </Route>
        <Route path={`${match.url}listening/wordorder`} exact>
          <ListeningWordOrder />
        </Route>
        <Route path={`${match.url}listening/truefalse`} exact>
          <ListeningTrueFalse />
        </Route>
        <Route path={`${match.url}listening/video`} exact>
          <ListeningVideo />
        </Route>
        <Route path={`${match.url}listening/listening`} exact>
          <ListeningLetterOrder />
        </Route>
        {/* LISTENING ROUTES END */}
      </Switch>
    </div>
  </div>
);

export default AddQuestionContent;
