import {
  GET_LIST_OF_QUESTIONS_REQUEST,
  GET_LIST_OF_QUESTIONS_SUCCESS,
  GET_LIST_OF_QUESTIONS_FAILURE,
  EDIT_QUESTION_VALUE,
  EDIT_QUESTION_RATING,
  EDIT_QUESTION_POSITION,
  RUN_VALIDATE,
  SET_LIST_OF_QUESTIONS_PAGINATION,
  OPEN_EDIT_MODAL,
  CLOSE_EDIT_MODAL,
  GET_FILE,
} from '../constants/';
import { SUBSTITUTION_GRAMMAR, CORRECT_MISTAKE_GRAMMAR, TRUE_OR_FALSE_GRAMMAR } from '../../constants/globalConstants';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const getListOfQuestions = (page = 0, size = 10, sort = 'id,asc') => dispatch => {
  dispatch({ type: GET_LIST_OF_QUESTIONS_REQUEST });

  axios
    .get('/question', { params: { size, page, sort } })
    .then(res => {
      const preparedContent = res.data.content.map(item => {
        const answers = item.answers.map(answer => ({
          id: uuidv4(),
          value: answer.name,
          position: item.sentence.startsWith('_') ? answer.ordinalRank : Number.isInteger(answer.ordinalRank) ? answer.ordinalRank + 1 : null,
          type: 'answers',
        }));
        const condition = item.questionType === CORRECT_MISTAKE_GRAMMAR || item.questionType === SUBSTITUTION_GRAMMAR ? ' ' : '/';
        const questions = item.sentence.split(condition).map((sentence, index) => {
          if (item.questionType === TRUE_OR_FALSE_GRAMMAR) {
            return {
              id: uuidv4(),
              type: 'questions',
              value: sentence,
              correctly: item.answers[index].name,
            };
          }

          return {
            id: uuidv4(),
            type: 'questions',
            value: sentence,
          };
        });

        return { ...item, answers, questions };
      });

      if (res.status === 200) {
        dispatch({ type: GET_LIST_OF_QUESTIONS_SUCCESS, payload: { ...res.data, content: preparedContent } });
        dispatch({
          type: SET_LIST_OF_QUESTIONS_PAGINATION,
          payload: { currentPage: res.data.pageable.pageNumber, totalPages: res.data.totalPages },
        });
      }
    })
    .catch(error => {
      dispatch({ type: GET_LIST_OF_QUESTIONS_FAILURE, payload: error.response });
    });
};

export const editQuestionValue = data => dispatch => {
  dispatch({ type: EDIT_QUESTION_VALUE, payload: data });
};

export const editQuestionRating = data => dispatch => {
  dispatch({ type: EDIT_QUESTION_RATING, payload: data });
};

export const editQuestionPosition = data => dispatch => {
  dispatch({ type: EDIT_QUESTION_POSITION, payload: data });
};

export const setRunValidate = data => dispatch => {
  dispatch({ type: RUN_VALIDATE, payload: data });
};

export const getFile = fileId => dispatch => {
  return axios
    .get(`/file/${fileId}/download`)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: GET_FILE, payload: res.data });

        return res.data;
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const openEditModal = data => ({ type: OPEN_EDIT_MODAL, payload: data });

export const closeEditModal = () => ({ type: CLOSE_EDIT_MODAL });
