import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import styles from './Question.module.scss';
import { deletePopupOpen, openEditModal } from '../../store/actions/';

const Question = ({ question }) => {
  const dispatch = useDispatch();

  const date = moment(question.createdAt).format('DD/MM/YY');
  const category = `${question.topicType[0]}${question.topicType.slice(1).toLowerCase()}`;

  const handleOpenModal = useCallback(() => {
    dispatch(openEditModal(question));
  }, [dispatch, question]);

  const handleDeleteSentence = e => {
    e.stopPropagation();

    dispatch(deletePopupOpen(question.id, {}));
  };

  return (
    <section onClick={handleOpenModal}>
      <ul className={styles.questions__info}>
        <li className={styles.questions__infoItem}>
          {question.id}
        </li>
        <li className={styles.questions__infoItem}>
          {date}
        </li>
        <li className={styles.questions__infoItem}>
          {category}
        </li>
        <li className={styles.questions__infoItem}>
          {question.topicName}
        </li>
        <li className={styles.questions__infoItem}>
          {question.rating}
        </li>
        <li className={styles.questions__infoItem}>
          <button
            className={styles.questions__notApproveButton}
            onClick={handleDeleteSentence}
          />
        </li>
      </ul>
    </section>
  );
};

export default Question;
