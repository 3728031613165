import {
  QUESTION_POPUP_OPEN,
  QUESTION_POPUP_CLOSE,
  ADD_QUESTION_REQUEST,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAILURE,
  EDIT_QUESTION_POPUP_OPEN,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE,
  DELETE_QUESTION_POPUP_OPEN,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  SET_DUPLICATE_SENTENCES,
  ADD_QUESTION_LEVELS,
} from '../constants/';
import axios from 'axios';

export const popupOpen = (data, resetForm = () => {}) => dispatch => {
  dispatch({ type: QUESTION_POPUP_OPEN, payload: data, resetForm });
};

export const popupClose = () => dispatch => {
  dispatch({ type: QUESTION_POPUP_CLOSE });
};

export const editPopupOpen = (id, data) => dispatch => {
  dispatch({ type: EDIT_QUESTION_POPUP_OPEN, id, payload: data });
};

export const deletePopupOpen = (id, data) => dispatch => {
  dispatch({ type: DELETE_QUESTION_POPUP_OPEN, id, payload: data });
};

export const addQuestion = questionParams => dispatch => {
  dispatch({ type: ADD_QUESTION_REQUEST });
  return axios
    .post('question', questionParams)
    .then(res => {
      console.log(res);
      if (res.status === 200) {
        dispatch({ type: ADD_QUESTION_SUCCESS, payload: res.data });

        return res.data;
      }
    })
    .catch(error => {
      dispatch({ type: ADD_QUESTION_FAILURE, payload: error });
    });
};

export const attachFileToQuestion = requestData => dispatch => {
  dispatch({ type: ADD_QUESTION_REQUEST });
  axios
    .post('file', requestData)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: ADD_QUESTION_SUCCESS, payload: res.data });

        return res.data;
      }
    })
    .catch(error => {
      dispatch({ type: ADD_QUESTION_FAILURE, payload: error });
    });
};

export const editQuestion = (id, questionParams) => dispatch => {
  dispatch({ type: EDIT_QUESTION_REQUEST });
  return axios
    .put(`/question/${id}`, questionParams)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: EDIT_QUESTION_SUCCESS, payload: res.data });

        return res.data;
      }
    })
    .catch(error => {
      dispatch({ type: EDIT_QUESTION_FAILURE, payload: error });
    });
};

export const deleteQuestion = id => dispatch => {
  dispatch({ type: DELETE_QUESTION_REQUEST });
  axios
    .delete(`/question/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: DELETE_QUESTION_SUCCESS, payload: res.data });
      }
    })
    .catch(error => {
      dispatch({ type: DELETE_QUESTION_FAILURE, payload: error });
    });
};

export const getDuplicateSentencesByType = (type, sentence, page, size) => dispatch => {
  dispatch({ type: ADD_QUESTION_REQUEST });

  axios
    .get(`/question/search?questionType=${type}&words=${sentence}&page=${page}&size=${size}&sort=id,desc`)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: SET_DUPLICATE_SENTENCES, payload: res.data });
      }
    })
    .catch(error => {
      dispatch({ type: ADD_QUESTION_FAILURE, payload: error?.toJSON() });
    });
};

export const setQuestionLevels = () => dispatch => {
  axios
    .get('/question/levels')
    .then(res => {
      if (res.status === 200) {
        const data = res.data.reduce((acc, level) => {
          return {
            ...acc,
            [level.name]: { min: Number(level.minRating), max: Number(level.maxRating) },
          };
        }, {});

        dispatch({ type: ADD_QUESTION_LEVELS, payload: data });
      }
    })
    .catch(error => {
      dispatch({ type: ADD_QUESTION_FAILURE, payload: error?.toJSON() });
    });
};
