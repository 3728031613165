//Auth
export const SIGNIN_USER_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

//Question
export const QUESTION_POPUP_OPEN = 'QUESTION_POPUP_OPEN';
export const QUESTION_POPUP_CLOSE = 'QUESTION_POPUP_CLOSE';
export const ADD_QUESTION_REQUEST = 'ADD_QUESTION_REQUEST';
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS';
export const ADD_QUESTION_FAILURE = 'ADD_QUESTION_FAILURE';
export const ADD_QUESTION_LEVELS = 'ADD_QUESTION_LEVELS';

//Edit Question
export const RUN_VALIDATE = 'RUN_VALIDATE';
export const EDIT_QUESTION_VALUE = 'EDIT_QUESTION_VALUE';
export const EDIT_QUESTION_RATING = 'EDIT_QUESTION_RATING';
export const EDIT_QUESTION_POSITION = 'EDIT_QUESTION_POSITION';
export const EDIT_QUESTION_POPUP_OPEN = 'EDIT_QUESTION_POPUP_OPEN';
export const EDIT_QUESTION_REQUEST = 'EDIT_QUESTION_REQUEST';
export const EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS';
export const EDIT_QUESTION_FAILURE = 'EDIT_QUESTION_FAILURE';
export const DELETE_QUESTION_POPUP_OPEN = 'DELETE_QUESTION_POPUP_OPEN';
export const SET_DUPLICATE_SENTENCES = 'SET_DUPLICATE_SENTENCES';
export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE';

export const OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL';
export const CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL';

//List of questions
export const GET_LIST_OF_QUESTIONS_REQUEST = 'GET_LIST_OF_QUESTIONS_REQUEST';
export const GET_LIST_OF_QUESTIONS_SUCCESS = 'GET_LIST_OF_QUESTIONS_SUCCESS';
export const GET_LIST_OF_QUESTIONS_FAILURE = 'GET_LIST_OF_QUESTIONS_FAILURE';
export const GET_FILE = 'GET_FILE';
export const SET_LIST_OF_QUESTIONS_PAGINATION = 'SET_LIST_OF_QUESTIONS_PAGINATION';

//Aside menu
export const GET_MENU_OPTIONS_REQUEST = 'GET_MENU_OPTIONS_REQUEST';
export const GET_MENU_OPTIONS_SUCCESS = 'GET_MENU_OPTIONS_SUCCESS';
export const GET_MENU_OPTIONS_FAILURE = 'GET_MENU_OPTIONS_FAILURE';
export const SET_MENU_OPTIONS = 'SET_MENU_OPTIONS';
export const SET_CURRENT_MENU_OPTIONS = 'SET_CURRENT_MENU_OPTIONS';
export const SET_TOPICS_AND_TYPES = 'SET_TOPICS_AND_TYPES';
export const SET_MENU_CURRENT_LEVEL = 'SET_MENU_CURRENT_LEVEL';

//Statistics
export const SET_STATISTICS = 'SET_STATISTICS';
export const SET_STATISTICS_RATING = 'SET_STATISTICS_RATING';
export const SET_STATISTICS_LEVEL = 'SET_STATISTICS_LEVEL';
export const SET_STATISTICS_ERROR = 'SET_STATISTICS_ERROR';
export const SET_ACTIVATE_LOADER = 'SET_ACTIVATE_LOADER';

//Dictionary
export const SET_DICTIONARY_LEVELS = 'SET_DICTIONARY_LEVELS';
export const SET_DICTIONARY_CATEGORIES = 'SET_DICTIONARY_CATEGORIES';
export const SET_DICTIONARY_WORDS = 'SET_DICTIONARY_WORDS';
export const EDIT_DICTIONARY_WORD = 'EDIT_DICTIONARY_WORD';
export const SET_DICTIONARY_ERROR = 'SET_DICTIONARY_ERROR';
export const RESET_DICTIONARY_ERROR = 'RESET_DICTIONARY_ERROR';
export const SET_ACTIVATE_DICTIONARY_LOADER = 'SET_ACTIVATE_DICTIONARY_LOADER';

//Tasks
export const SET_TASKS = 'SET_TASKS';
export const SET_TASKS_ERROR = 'SET_TASKS_ERROR';
export const TURN_ON_TASKS_LOADER = 'TURN_ON_TASKS_LOADER';
export const SET_TASKS_MODAL_STATE = 'SET_TASKS_MODAL_STATE';
