import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import styles from './GrammarGroup.module.scss';
import FKInput from '../../../../components/FKInput/FKInput';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import { LIST_OF_LEVELS, RATING_MAX, RATING_MIN } from '../../../../constants/globalConstants';
import AddButton from '../../../../components/AddButton/AddButton';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import { editPopupOpen, popupOpen } from '../../../../store/actions';
import EditPopupButtons from '../../../../components/EditPopupButtons/EditPopupButtons';
import DuplicateSentenceSearch from '../../../../components/DuplicateSentenceSearch/DuplicateSentenceSearch';
import FKSelect from '../../../../components/FKSelect/FKSelect';
import { getFormattedTime } from '../../../../constants/globalFunctions';

const GrammarGroup = ({ question, handleClosePopup = () => {} }) => {
  const { levels } = useSelector(({ question }) => question);
  const { options } = useSelector(({ asideMenu }) => asideMenu);
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);
  const INPUT_WIDTH = 200 + Boolean(!isEditPage) * 50;
  const { pathname } = useLocation();
  const hideDuplicateSentenceSearch = pathname !== '/questions' && isEditPage;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      sentences: Yup.array().of(
        Yup.object().shape({
          sentence: Yup.string()
            .max(21)
            .required(),
        }),
      ),
      answers: Yup.array().of(
        Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .max(21)
              .required(),
          }),
        ),
      ),
      rating: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .required(),
      defaultRating: Yup.number()
        .test('', function(value) {
          const level = this.parent.level?.value;

          if (level) {
            return value >= levels[level].min && value <= levels[level].max;
          }

          return false;
        })
        .required(),
      time: Yup.number()
        .min(3)
        .max(180)
        .required(),
      level: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(),
    });
  }, [levels]);

  const initialState = {
    sentences: [
      { sentence: '', id: uuid() },
      { sentence: '', id: uuid() },
    ],
    answers: [[{ name: '', id: uuid(), ordinalRank: null }], []],
    rating: 0,
    defaultRating: 0,
    time: 0,
    level: null,
  };

  const listOfTopics = useMemo(() => {
    if (question) {
      const { topics } = options.find(({ topicType }) => topicType === question.topicType);

      return topics.map(({ name }) => ({ label: name, value: name }));
    }

    return [];
  }, [options, question]);

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const requestSentences = values.sentences.map(({ sentence }) => sentence.trim());
      const answerList = values.answers.map((list, index) => list.map(item => ({ ...item, name: item.name.trim(), ordinalRank: index + 1 }))).flat();

      const requestData = {
        sentences: requestSentences,
        answers: answerList,
        rating: values.rating,
        recommendedTime: getFormattedTime(values.time),
        level: values.level.value,
        defaultRating: values.defaultRating,
        ...(isEditPage && {
          topicName: values.topicName.value,
          topicType: question.topicType,
        }),
      };

      if (isEditPage) {
        const { id } = question;

        dispatch(editPopupOpen(id, requestData));
      } else {
        dispatch(popupOpen(requestData, resetForm));
      }

      setSubmitting(false);
    },
    [dispatch, isEditPage, question],
  );

  return (
    <Formik initialValues={question || initialState} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>
      {({ values, setFieldValue }) => {
        const disabledDeleteAnswerBtn = values.answers.flat().length < 2;
        const levelRatingRange = values.level ? levels[values.level.value] : { min: 0, max: 0 };

        const addNewExercise = () => {
          const newAnswer = [];
          const newSentence = { sentence: '', id: uuid() };

          setFieldValue('sentences', [...values.sentences, newSentence]);
          setFieldValue('answers', [...values.answers, newAnswer]);
        };

        const addNewAnswer = index => {
          const newAnswer = { name: '', id: uuid(), ordinalRank: null };

          setFieldValue(`answers.${index}`, [...values.answers[index], newAnswer]);
        };

        const removeAnswer = (questionIndex, answerId) => {
          const sentenceList = values.answers[questionIndex].filter(({ id }) => id !== answerId);

          setFieldValue(`answers.${questionIndex}`, sentenceList);
        };

        return (
          <Form className={styles.formContainer}>
            {!hideDuplicateSentenceSearch && <DuplicateSentenceSearch type='GROUP_GRAMMAR' />}
            <section className={styles.exercisesWrapper}>
              <FieldArray name='sentences'>
                {sentencesArrayHelpers =>
                  values.sentences.map((question, questionIndex) => {
                    const disabledDeleteBtn = values.answers.length < 3 || (disabledDeleteAnswerBtn && values.answers[questionIndex].length);
                    const showAddAnswerBtn = values.answers[questionIndex].length < 3;

                    const removeSentence = () => {
                      const newAnswersList = values.answers.filter((_, index) => questionIndex !== index);

                      sentencesArrayHelpers.remove(questionIndex);
                      setFieldValue('answers', newAnswersList);
                    };

                    return (
                      <div className={styles.exerciseContainer} key={question.id}>
                        <div className={styles.questionContainer}>
                          <Field
                            name={`sentences.${questionIndex}.sentence`}
                            component={FKInput}
                            placeholder='Enter your question'
                            backgroundColor='#FFF'
                            height={44}
                          />
                          <DeleteButton onClick={removeSentence} disabled={disabledDeleteBtn} />
                        </div>
                        <div className={styles.answerContainer}>
                          <FieldArray name='answers'>
                            {() => (
                              <div>
                                {values.answers[questionIndex].map((answer, answerIndex) => (
                                  <div className={styles.fieldContainer} key={answer.id}>
                                    <Field
                                      name={`answers.${questionIndex}.${answerIndex}.name`}
                                      component={FKInput}
                                      placeholder='Enter your question'
                                      backgroundColor='#FFF'
                                      height={44}
                                    />
                                    <DeleteButton onClick={() => removeAnswer(questionIndex, answer.id)} disabled={disabledDeleteAnswerBtn} />
                                  </div>
                                ))}
                                {showAddAnswerBtn && (
                                  <div className={styles.addBtnContainer}>
                                    <AddButton onClick={() => addNewAnswer(questionIndex)} />
                                  </div>
                                )}
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      </div>
                    );
                  })
                }
              </FieldArray>
              <div>
                {values.sentences.length < 3 && (
                  <div className={styles.emptyContainer}>
                    <AddButton onClick={addNewExercise} />
                  </div>
                )}
              </div>
            </section>
            <section className={styles.additionalSettings}>
              {isEditPage && (
                <div style={{ marginRight: 50 }}>
                  <h2>Topic</h2>
                  <Field
                    name='topicName'
                    component={FKSelect}
                    placeholder='Select your topic'
                    height={44}
                    width={INPUT_WIDTH}
                    options={listOfTopics}
                  />
                </div>
              )}
              <div>
                <h2>Time (s)</h2>
                <Field name='time' component={FKInput} placeholder='Enter your time' height={44} width={INPUT_WIDTH} type='number' />
              </div>
              <div style={{ marginLeft: 50 }}>
                <h2>Level</h2>
                <Field name='level' component={FKSelect} placeholder='Select your level' height={44} width={INPUT_WIDTH} options={LIST_OF_LEVELS} />
              </div>
              <div style={{ marginLeft: 50, opacity: values.level ? 1 : 0.5 }}>
                <h2>
                  DefaultRating ({levelRatingRange.min} - {levelRatingRange.max})
                </h2>
                <Field
                  name='defaultRating'
                  component={FKInput}
                  min={levelRatingRange.min}
                  max={levelRatingRange.max}
                  placeholder='Enter your default rating'
                  disabled={!values.level}
                  height={44}
                  width={INPUT_WIDTH}
                  type='number'
                />
              </div>
            </section>
            {isEditPage ? <EditPopupButtons handleClose={handleClosePopup} /> : <SaveButton type='submit' />}
          </Form>
        );
      }}
    </Formik>
  );
};

export default GrammarGroup;
