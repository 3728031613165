import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSpeechSynthesis } from 'react-speech-kit';
import imageCompression from 'browser-image-compression';
import Tooltip from 'react-simple-tooltip';
import ReactPaginate from 'react-paginate';
import { v4 } from 'uuid';

import styles from './Words.module.scss';
import { ReactComponent as SoundIcon } from '../../../img/sound.svg';
import { ReactComponent as UploadIcon } from '../../../img/upload.svg';
import { ReactComponent as CheckMarkIcon } from '../../../img/checkMark.svg';
import { ReactComponent as CrossIcon } from '../../../img/cross.svg';
import { ReactComponent as AddImageIcon } from '../../../img/addImg.svg';
import { ReactComponent as EditIcon } from '../../../img/edit.svg';
import { ReactComponent as TrashIcon } from '../../../img/trash.svg';
import { ReactComponent as NextIcon } from '../../../img/next.svg';
import { ReactComponent as RemoveIcon } from '../../../img/remove.svg';
import {
  createDictionaryNewWord,
  deleteDictionaryWord,
  editDictionaryWord,
  resetDictionaryError,
  setDictionaryWords,
} from '../../../store/actions/Dictionary';
import Modal from '../../../components/Modal/Modal';
import Spinner from '../../../components/Spinner/Spinner';

const tableColumnsTitle = [
  { name: 'Date', id: v4() },
  { name: 'Word', id: v4() },
  { name: 'Description', id: v4() },
  { name: 'Sound', id: v4() },
  { name: 'Image', id: v4() },
  { name: 'View', id: v4() },
];
const compressionOptions = {
  maxSizeMB: 100,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const PAGE_SIZE = 9;

const Words = () => {
  const { levelId, categoryId } = useParams();

  const dispatch = useDispatch();
  const { words, loading, countOfPages, error, totalElements } = useSelector(({ dictionary }) => dictionary);

  const { speak } = useSpeechSynthesis();

  const [word, setWord] = useState('');
  const [description, setDescription] = useState('');
  const [searchParam, setSearchParam] = useState('');
  const [page, setPage] = useState(0);
  const [editWordId, setEditWordId] = useState(null);
  const [editWord, setEditWord] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [image, setImage] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [currentWord, setCurrentWord] = useState(null);
  const [createWordPopupIsVisible, setCreateWordPopupIsVisible] = useState(false);
  const [editWordPopupIsVisible, setEditWordPopupIsVisible] = useState(false);
  const [deleteWordPopupIsVisible, setDeleteWordPopupIsVisible] = useState(false);

  const saveWordIsDisabled = useMemo(() => {
    return !(word && description && image);
  }, [word, description, image]);

  const getImgName = useCallback(word => {
    if (word.length > 12) {
      return `${word.slice(0, 12)}.jpg`;
    }

    return `${word}.jpg`;
  }, []);

  const getFormattedWord = useCallback(word => {
    const replaceWord = word.replaceAll('_', ' ');

    return replaceWord[0].toUpperCase() + replaceWord.slice(1).toLowerCase();
  }, []);

  const handleChangeWord = useCallback(({ target }) => {
    setWord(target.value);
  }, []);

  const handleChangeEditWord = useCallback(({ target }) => {
    setEditWord(target.value);
  }, []);

  const handleChangeDescription = useCallback(({ target }) => {
    setDescription(target.value);
  }, []);

  const handleChangeEditDescription = useCallback(({ target }) => {
    setEditDescription(target.value);
  }, []);

  const handleOpenCreateWordPopup = useCallback(() => {
    setCreateWordPopupIsVisible(true);
  }, []);

  const handleOpenEditWordPopup = useCallback(() => {
    if (editWord && editDescription) {
      setEditWordPopupIsVisible(true);
    }
  }, [editWord, editDescription]);

  const handleCloseCreateWordPopup = useCallback(() => {
    setCreateWordPopupIsVisible(false);
    setCurrentWord(null);
  }, []);

  const handleCloseEditWordPopup = useCallback(() => {
    setEditWordPopupIsVisible(false);
  }, []);

  const handleOpenDeleteWordPopup = useCallback(wordId => {
    setCurrentWord(wordId);
    setDeleteWordPopupIsVisible(true);
  }, []);

  const handleCloseDeleteWordPopup = useCallback(() => {
    setDeleteWordPopupIsVisible(false);
  }, []);

  const handleUploadImage = useCallback(({ target }, setFile) => {
    const file = target.files[0];

    if (file.size > 1000000) {
      return alert('File is too big!\n Should be < 1MB');
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function({ target: { result } }) {
      const image = new Image();
      image.src = result;

      image.onload = function() {
        if (this.height !== this.width) {
          return alert('Incorrect image size!\n Should be 1:1 (height = width)');
        }

        setFile(file);
      };
    };
  }, []);

  const handleDeleteWord = useCallback(() => {
    dispatch(deleteDictionaryWord(currentWord, categoryId, levelId, page, PAGE_SIZE, searchParam));
    handleCloseDeleteWordPopup();
  }, [dispatch, currentWord, handleCloseDeleteWordPopup, categoryId, levelId, page, searchParam]);

  const handleClearFields = useCallback(() => {
    setWord('');
    setDescription('');
    setImage('');
  }, []);

  const handlePlayAudio = useCallback(
    value => {
      speak({ text: value });
    },
    [speak],
  );

  const handleChangeSearchParam = useCallback(({ target }) => {
    setSearchParam(target.value);
  }, []);

  const handleCloseErrorModal = useCallback(() => {
    dispatch(resetDictionaryError());
  }, [dispatch]);

  const handleChangePage = useCallback(
    page => {
      setPage(page.selected);

      dispatch(setDictionaryWords(categoryId, levelId, page.selected, PAGE_SIZE, searchParam));
    },
    [dispatch, categoryId, levelId, searchParam],
  );

  const handleEditWord = useCallback(
    wordId => {
      const currentWord = words.find(({ id }) => wordId === id);
      setEditWordId(wordId);
      setEditWord(currentWord.word);
      setEditDescription(currentWord.description);
    },
    [words],
  );

  const handleCancelWordEditing = useCallback(() => {
    setEditWordId(null);
    setEditWord('');
    setEditDescription('');
    setEditImage(null);
  }, []);

  const handleRemoveSearchParam = useCallback(() => {
    setSearchParam('');
    setPage(0);
    dispatch(setDictionaryWords(categoryId, levelId, 0, PAGE_SIZE, ''));
  }, [dispatch, categoryId, levelId]);

  const handleSearch = useCallback(() => {
    dispatch(setDictionaryWords(categoryId, levelId, page, PAGE_SIZE, searchParam));
    setPage(0);
  }, [dispatch, categoryId, levelId, page, searchParam]);

  const handleSaveWord = useCallback(async () => {
    try {
      const compressedFile = await imageCompression(image, compressionOptions);

      const formData = new FormData();
      formData.append('image', compressedFile);
      formData.append('category', categoryId);
      formData.append('description', description.trim());
      formData.append('level', levelId);
      formData.append('word', word.trim().toLowerCase());

      dispatch(createDictionaryNewWord(formData, categoryId, levelId, page, PAGE_SIZE, searchParam));
      handleClearFields();
      handleCloseCreateWordPopup();
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, image, categoryId, description, levelId, word, handleClearFields, handleCloseCreateWordPopup, page, searchParam]);

  const handleSaveEditWord = useCallback(async () => {
    try {
      let compressedFile;

      if (editImage) {
        compressedFile = await imageCompression(editImage, compressionOptions);
      }

      const formData = new FormData();
      compressedFile && formData.append('image', compressedFile);
      formData.append('description', editDescription.trim());
      formData.append('word', editWord.trim().toLowerCase());

      dispatch(editDictionaryWord(formData, editWordId));
      handleCancelWordEditing();
      handleCloseEditWordPopup();
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, editWordId, editImage, editDescription, editWord, handleCancelWordEditing, handleCloseEditWordPopup]);

  useEffect(() => {
    dispatch(setDictionaryWords(categoryId, levelId, page, PAGE_SIZE, ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.pageContainer}>
      <Modal
        isOpen={createWordPopupIsVisible}
        handleCloseModal={handleCloseCreateWordPopup}
        title='Are you sure?'
        description='By clicking “Yes” you confirm adding a new word in the system.'
        okOnClick={handleSaveWord}
        cancelOnClick={handleCloseCreateWordPopup}
      />
      <Modal
        isOpen={editWordPopupIsVisible}
        handleCloseModal={handleCloseEditWordPopup}
        title='Are you sure?'
        description='By clicking “Yes” you confirm editing a word in the system.'
        okOnClick={handleSaveEditWord}
        cancelOnClick={handleCloseEditWordPopup}
      />
      <Modal
        isOpen={deleteWordPopupIsVisible}
        handleCloseModal={handleCloseDeleteWordPopup}
        title='Are you sure?'
        description={`By clicking “Yes” you remove a word in the system.`}
        okOnClick={handleDeleteWord}
        cancelOnClick={handleCloseDeleteWordPopup}
      />
      <Modal
        isOpen={error}
        handleCloseModal={handleCloseErrorModal}
        title='Ooops'
        description='Something went wrong please try again'
        okOnClick={handleCloseErrorModal}
        okLabel='Ok'
        cancelLabel={null}
      />
      <div className={styles.pageHeader}>
        <div className={styles.breadcrumbs}>
          <Link to='/dictionary/levels' className={styles.breadcrumbsItem}>
            Dictionary
          </Link>
          <span className={styles.breadcrumbsItem}> &gt; </span>
          <Link to={`/dictionary/levels/${levelId}/categories`} className={styles.breadcrumbsItem}>
            Level: {getFormattedWord(levelId)}
          </Link>
          <span className={styles.breadcrumbsItem}> &gt; </span>
          <span className={styles.breadcrumbsCurrentItem}>{getFormattedWord(categoryId)}: List of words</span>
        </div>
        <div className={styles.actionContainer}>
          <div className={styles.searchContainer}>
            <div className={styles.searchInputContainer}>
              {searchParam && <TrashIcon className={styles.trashIcon} onClick={handleRemoveSearchParam} />}
              <input className={styles.searchInput} placeholder='Search by word' value={searchParam} onChange={handleChangeSearchParam} />
            </div>
            <button className={styles.searchButton} onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={styles.wordsTableContainer}>
        <ul className={styles.wordsTableHeader}>
          {tableColumnsTitle.map(({ name, id }) => (
            <li key={id} className={styles.wordsTableHeaderTitle}>
              {name}
            </li>
          ))}
        </ul>
        <div className={styles.createWordContainer}>
          <span>{moment().format('DD/MM/YY')}</span>
          <input placeholder='Enter word' className={styles.input} value={word} onChange={handleChangeWord} max={20} />
          <input placeholder='Enter description' className={styles.input} value={description} onChange={handleChangeDescription} max={200} />
          <button
            onClick={() => handlePlayAudio(word)}
            className={styles.sound}
            disabled={!word}
            style={{ opacity: word ? 1 : 0.5, cursor: word ? 'pointer' : 'default' }}
          >
            <SoundIcon />
          </button>
          {image ? (
            <div className={styles.uploadPreview}>
              <label className={styles.sound}>
                <input type='file' className={styles.uploadInput} onChange={e => handleUploadImage(e, setImage)} accept='image/x-png,image/jpeg' />
                <AddImageIcon />
              </label>
              <span className={styles.imgName}>{getImgName(word || 'image')}</span>
            </div>
          ) : (
            <label className={styles.upload}>
              <input type='file' className={styles.uploadInput} onChange={e => handleUploadImage(e, setImage)} accept='image/x-png,image/jpeg' />
              <UploadIcon />
              <div className={styles.uploadTitle}>
                <span>Upload image</span>
                <span>(jpg, png. 500x500)</span>
              </div>
            </label>
          )}
          <button
            onClick={handleOpenCreateWordPopup}
            className={styles.save}
            disabled={saveWordIsDisabled}
            style={{ opacity: saveWordIsDisabled ? 0.5 : 1 }}
          >
            <CheckMarkIcon style={{ marginRight: 5 }} />
            Save word
          </button>
        </div>
        {words.map(({ description, image, word, createdAt, id }, index) => (
          <div key={id} style={{ backgroundColor: index % 2 ? '#fff' : '#F2F2F2' }}>
            {editWordId === id ? (
              <div className={styles.createWordContainer}>
                <span>{moment(createdAt).format('DD/MM/YY')}</span>
                <input placeholder='Enter word' className={styles.input} value={editWord} onChange={handleChangeEditWord} max={20} />
                <input
                  placeholder='Enter description'
                  className={styles.input}
                  value={editDescription}
                  onChange={handleChangeEditDescription}
                  max={200}
                />
                <button
                  onClick={() => handlePlayAudio(editWord)}
                  className={styles.sound}
                  disabled={!editWord}
                  style={{ opacity: editWord ? 1 : 0.5, cursor: editWord ? 'pointer' : 'default' }}
                >
                  <SoundIcon />
                </button>
                {image ? (
                  <div className={styles.uploadPreview}>
                    <label className={styles.sound}>
                      <input
                        type='file'
                        className={styles.uploadInput}
                        onChange={e => handleUploadImage(e, setEditImage)}
                        accept='image/x-png,image/jpeg'
                      />
                      <AddImageIcon />
                    </label>
                    <span className={styles.imgName}>{getImgName(editWord || 'image')}</span>
                  </div>
                ) : (
                  <label className={styles.upload}>
                    <input
                      type='file'
                      className={styles.uploadInput}
                      onChange={e => handleUploadImage(e, setEditImage)}
                      accept='image/x-png,image/jpeg'
                    />
                    <UploadIcon />
                    <div className={styles.uploadTitle}>
                      <span>Upload image</span>
                      <span>(jpg, png. 500x500)</span>
                    </div>
                  </label>
                )}
                <div>
                  <CheckMarkIcon
                    style={{ opacity: editWord && editDescription ? 1 : 0.5, cursor: editWord && editDescription ? 'pointer' : 'default' }}
                    className={styles.actionBtn}
                    onClick={handleOpenEditWordPopup}
                  />
                  <CrossIcon className={styles.actionBtn} onClick={handleCancelWordEditing} />
                </div>
              </div>
            ) : (
              <div className={styles.createWordContainer}>
                <span>{moment(createdAt).format('DD/MM/YY')}</span>
                <span>{word}</span>
                <Tooltip content={description} background='#0F0F0F'>
                  <span className={styles.description}>{description}</span>
                </Tooltip>
                <button onClick={() => handlePlayAudio(word)} className={styles.sound}>
                  <SoundIcon />
                </button>
                <div>
                  <img src={image} alt={word} className={styles.imgPreview} />
                  <a href={image} target='_blank' rel='noopener noreferrer' className={styles.imgName}>
                    {getImgName(word)}
                  </a>
                </div>
                <div>
                  <EditIcon onClick={() => handleEditWord(id)} className={styles.actionBtn} />
                  <RemoveIcon onClick={() => handleOpenDeleteWordPopup(id)} className={styles.actionBtn} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {totalElements > PAGE_SIZE && (
        <ReactPaginate
          previousLabel={<NextIcon style={{ transform: 'rotate(180deg)' }} />}
          nextLabel={<NextIcon />}
          pageCount={countOfPages}
          forcePage={page}
          onPageChange={handleChangePage}
          containerClassName={styles.paginationContent}
          pageClassName={styles.paginationButton}
          previousLinkClassName={styles.paginationNavigationButton}
          nextLinkClassName={styles.paginationNavigationButton}
          disabledClassName={styles.paginationDisabledButton}
          activeClassName={styles.paginationActiveButton}
        />
      )}
    </div>
  );
};

export default Words;
