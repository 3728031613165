import React from 'react';

import styles from '../EditPopupButtons/EditPopupButtons.module.scss';

const EditGrammarPopupButtons = ({ handleClose = () => {}, handleSubmit = () => {} }) => (
  <div className={styles.btnContainer}>
    <button type='button' onClick={handleClose} className={styles.cancelButton}>
      Cancel
    </button>
    <button type='button' onClick={handleSubmit} className={styles.confirmButton}>
      Approve
    </button>
  </div>
);

export default EditGrammarPopupButtons;
