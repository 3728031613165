import React from 'react';

import styles from './EditPopupButtons.module.scss';

const EditPopupButtons = ({ handleClose }) => (
  <div className={styles.btnContainer}>
    <button type='button' onClick={handleClose} className={styles.cancelButton}>
      Cancel
    </button>
    <button type='submit' className={styles.confirmButton}>
      Approve
    </button>
  </div>
);

export default EditPopupButtons;
