import React from 'react';

import styles from './FKCheckbox.module.scss';
import checkboxChecked from '../../img/icons/checkbox-checked.svg';
import checkboxNotChecked from '../../img/icons/checkbox-not-checked.svg';

const FkCheckbox = ({ onChange, value, label, id }) => {
  return (
    <div>
      <label htmlFor={id} className={styles.label}>
        <input id={id} type='checkbox' className={styles.checkbox} checked={value} onChange={onChange} />
        <img src={value ? checkboxChecked : checkboxNotChecked} className={styles.img} alt={value ? 'Checked' : 'Unchecked'} />
        {label}
      </label>
    </div>
  );
};

export default FkCheckbox;
