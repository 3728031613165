import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import styles from './Header.module.scss';
import { logoutUserRequest } from '../../store/actions';

const Header = () => {
  const dispatch = useDispatch();
  const { currentOptions } = useSelector(({ asideMenu }) => asideMenu);

  const isSuperAdmin = Boolean(JSON.parse(localStorage.getItem('super_admin')));

  const generateLinkPath = index => {
    return `${currentOptions[index].name.replace(/\W/g, '').toLowerCase()}`;
  };

  const addQuestionLink = !currentOptions[1].name && !currentOptions[2].name ? 'grammar' : `/${generateLinkPath(0)}/${generateLinkPath(2)}`;

  const handleLogout = useCallback(() => {
    dispatch(logoutUserRequest());
  }, [dispatch]);

  return (
    <header className={styles.header_wrap}>
      <div className={styles.header_menu_wrap}>
        <Link to={addQuestionLink} className={styles.header_logo_wrap}>
          <div className={styles.header_logo} />
        </Link>

        <div className={styles.header_menu_navbar}>
          <NavLink to={addQuestionLink} className={styles.header_link} activeClassName={styles.header_link_active}>
            Add question
          </NavLink>

          {isSuperAdmin && (
            <NavLink to='/questions' className={styles.header_link} activeClassName={styles.header_link_active}>
              Not Approved
            </NavLink>
          )}

          {isSuperAdmin && (
            <NavLink to='/tasks' className={styles.header_link} activeClassName={styles.header_link_active}>
              Approved
            </NavLink>
          )}

          <NavLink to='/statistics' className={styles.header_link} activeClassName={styles.header_link_active}>
            Statistics
          </NavLink>

          <NavLink to='/dictionary/levels' className={styles.header_link} activeClassName={styles.header_link_active}>
            Dictionary
          </NavLink>
        </div>
      </div>

      <button className={styles.header_logoutButton} onClick={handleLogout}>
        Log out
      </button>
    </header>
  );
};

export default Header;
