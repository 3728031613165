import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik, FieldArray } from 'formik';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import styles from './VocabularyDefinition.module.scss';
import FKInput from '../../../../components/FKInput/FKInput';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import AddButton from '../../../../components/AddButton/AddButton';
import SquareSelect from '../../../../components/SquareSelect/SquareSelect';
import { LIST_OF_LEVELS, RATING_MAX, RATING_MIN } from '../../../../constants/globalConstants';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import { editPopupOpen, popupOpen } from '../../../../store/actions';
import EditPopupButtons from '../../../../components/EditPopupButtons/EditPopupButtons';
import DuplicateSentenceSearch from '../../../../components/DuplicateSentenceSearch/DuplicateSentenceSearch';
import FKSelect from '../../../../components/FKSelect/FKSelect';
import { getFormattedTime } from '../../../../constants/globalFunctions';

const VocabularyDefinition = ({ question, handleClosePopup = () => {} }) => {
  const { levels } = useSelector(({ question }) => question);
  const { options } = useSelector(({ asideMenu }) => asideMenu);
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);
  const INPUT_WIDTH = 200 + Boolean(!isEditPage) * 50;
  const { pathname } = useLocation();
  const hideDuplicateSentenceSearch = pathname !== '/questions' && isEditPage;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      sentences: Yup.array().of(
        Yup.object().shape({
          sentence: Yup.string().required(),
        }),
      ),
      answers: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .max(20)
            .required(),
        }),
      ),
      rating: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .required(),
      defaultRating: Yup.number()
        .test('', function(value) {
          const level = this.parent.level?.value;

          if (level) {
            return value >= levels[level].min && value <= levels[level].max;
          }

          return false;
        })
        .required(),
      time: Yup.number()
        .min(3)
        .max(180)
        .required(),
      level: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(),
    });
  }, [levels]);

  const initialState = {
    sentences: [{ sentence: '', id: uuid() }],
    answers: [
      { name: '', id: uuid(), ordinalRank: 1 },
      { name: '', id: uuid(), ordinalRank: null },
      { name: '', id: uuid(), ordinalRank: null },
    ],
    rating: 0,
    defaultRating: 0,
    time: 0,
    level: null,
  };

  const listOfTopics = useMemo(() => {
    if (question) {
      const { topics } = options.find(({ topicType }) => topicType === question.topicType);

      return topics.map(({ name }) => ({ label: name, value: name }));
    }

    return [];
  }, [options, question]);

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const requestSentences = values.sentences.map(({ sentence }) => sentence);
      const trimAnswers = values.answers.map(item => ({ ...item, name: item.name.trim() }));

      const requestData = {
        answers: trimAnswers,
        sentences: requestSentences,
        rating: values.rating,
        recommendedTime: getFormattedTime(values.time),
        level: values.level.value,
        defaultRating: values.defaultRating,
        ...(isEditPage && {
          topicName: values.topicName.value,
          topicType: question.topicType,
        }),
      };

      if (isEditPage) {
        const { id } = question;

        dispatch(editPopupOpen(id, requestData));
      } else {
        dispatch(popupOpen(requestData, resetForm));
      }

      setSubmitting(false);
    },
    [dispatch, isEditPage, question],
  );

  return (
    <Formik initialValues={question || initialState} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>
      {({ values, setFieldValue }) => {
        const correctAnswer = values.answers.find(({ ordinalRank }) => ordinalRank);
        const levelRatingRange = values.level ? levels[values.level.value] : { min: 0, max: 0 };
        const disabledAddBtn = values.answers.length >= 6;
        const selectOptionList = [1];

        const handleChangeAnswer = (i, answer) => {
          values.answers.forEach((item, index) => {
            if (item.ordinalRank === answer) {
              setFieldValue(`answers.${index}.ordinalRank`, null);
            }
          });

          setFieldValue(`answers.${i}.ordinalRank`, answer);
        };

        return (
          <Form className={styles.formContainer}>
            {!hideDuplicateSentenceSearch && <DuplicateSentenceSearch type='DEFINITION_VOCABULARY' />}
            <section>
              <section className={styles.questionWrapper}>
                <h3 className={styles.title}>Question</h3>
                <FieldArray name='sentences'>
                  {() =>
                    values.sentences.map((question, i) => (
                      <Field key={question.id} name={`sentences.${i}.sentence`} component={FKInput} placeholder='Enter your question' width='70%' />
                    ))
                  }
                </FieldArray>
              </section>
              <section className={styles.answerWrapper}>
                <h3 className={styles.title}>Answers</h3>
                <FieldArray name='answers'>
                  {arrayHelpers => (
                    <div>
                      <div className={styles.fieldsContainer}>
                        {values.answers.map((answer, i) => {
                          const disabledDeleteBtn = values.answers.length < 4 || answer.ordinalRank;

                          return (
                            <div className={styles.answerContainer} key={answer.id}>
                              <div className={styles.answer}>
                                <Field key={answer.id} name={`answers.${i}.name`} component={FKInput} placeholder='Enter your answer' width='100%' />
                                <SquareSelect
                                  optionsList={selectOptionList}
                                  handleChange={(_, index) => handleChangeAnswer(i, index)}
                                  position={answer.ordinalRank}
                                />
                                <DeleteButton disabled={disabledDeleteBtn} onClick={() => arrayHelpers.remove(i)} />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className={styles.buttonSpace}>
                        <AddButton disabled={disabledAddBtn} onClick={() => arrayHelpers.push({ name: '', id: uuid() })} />
                      </div>
                    </div>
                  )}
                </FieldArray>
              </section>
            </section>
            <section>
              <section className={styles.additionalSettings}>
                {isEditPage && (
                  <div style={{ marginRight: 50 }}>
                    <h2>Topic</h2>
                    <Field
                      name='topicName'
                      component={FKSelect}
                      placeholder='Select your topic'
                      height={44}
                      width={INPUT_WIDTH}
                      options={listOfTopics}
                    />
                  </div>
                )}
                <div>
                  <h2>Time (s)</h2>
                  <Field name='time' component={FKInput} placeholder='Enter your time' height={44} width={INPUT_WIDTH} type='number' />
                </div>
                <div style={{ marginLeft: 50 }}>
                  <h2>Level</h2>
                  <Field name='level' component={FKSelect} placeholder='Select your level' height={44} width={INPUT_WIDTH} options={LIST_OF_LEVELS} />
                </div>
                <div style={{ marginLeft: 50, opacity: values.level ? 1 : 0.5 }}>
                  <h2>
                    DefaultRating ({levelRatingRange.min} - {levelRatingRange.max})
                  </h2>
                  <Field
                    name='defaultRating'
                    component={FKInput}
                    min={levelRatingRange.min}
                    max={levelRatingRange.max}
                    placeholder='Enter your default rating'
                    disabled={!values.level}
                    height={44}
                    width={INPUT_WIDTH}
                    type='number'
                  />
                </div>
              </section>
              <section>
                <h3 className={styles.title}>Preview</h3>
                <div className={styles.previewContainer}>
                  <div className={styles.previewQuestion}>{values.sentences[0].sentence}</div>
                  <div className={styles.previewAnswer}>{correctAnswer?.name}</div>
                </div>
              </section>
              {isEditPage ? <EditPopupButtons handleClose={handleClosePopup} /> : <SaveButton type='submit' />}
            </section>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VocabularyDefinition;
