import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { popupClose, addQuestion, attachFileToQuestion, deleteQuestion } from '../../store/actions';
import cn from 'classnames';
import styles from './Popup.module.scss';

import Spinner from '../Spinner/Spinner';

const NewPopup = () => {
  const { selectedId, isPopupOpen, data, creationSuccess, error, loading, resetForm } = useSelector(({ question }) => question);
  const { selectedOptions } = useSelector(({ asideMenu }) => asideMenu);
  const dispatch = useDispatch();
  const isDeleteRequest = Object.keys(data).length === 0;
  const closePopup = () => dispatch(popupClose());
  const confirm = async () => {
    if (isDeleteRequest) {
      dispatch(deleteQuestion(selectedId));
    } else {
      const request = { ...data, topicId: selectedOptions[1].id, type: selectedOptions[2].type };
      const questionResponse = await dispatch(addQuestion(request));

      if (data?.file && questionResponse) {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('questionId', questionResponse.id);
        formData.append('type', questionResponse.questionType);

        await dispatch(attachFileToQuestion(formData));
      }

      if (questionResponse) {
        resetForm();
      }
    }
  };

  return (
    isPopupOpen && (
      <div className={styles.popup__background}>
        <div className={styles.popup}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <h2 className={styles.popup__title}>{error ? 'Ooops' : creationSuccess ? 'Congratulations' : 'Are you sure?'}</h2>
              <button type='button' onClick={closePopup} className={styles.popup__closeButton} />
              <p className={cn(`${styles.popup__text}`, { [`${styles.popup__error}`]: error }, { [`${styles.popup__success}`]: creationSuccess })}>
                {error
                  ? 'Something went wrong please try again'
                  : creationSuccess
                  ? isDeleteRequest
                    ? 'Sentence deleted successfully!'
                    : 'Sentence approved successfully!'
                  : isDeleteRequest
                  ? 'By clicking “Yes” you confirm deleting a question in the system'
                  : 'By clicking “Yes” you confirm adding a new question in the system'}
              </p>
              {!creationSuccess && (
                <div className={styles.popup__controls}>
                  <button type='button' onClick={closePopup} className={styles.popup__cancel}>
                    Cancel
                  </button>
                  <button type='button' onClick={confirm} className={styles.popup__confirm}>
                    {error ? 'Try again' : 'Yes'}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  );
};

export default NewPopup;
