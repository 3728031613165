import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import styles from './NewCorrectMistake.module.scss';

import { editPopupOpen, popupOpen } from '../../../../store/actions';

import TextInput from '../../../../components/TextInput/TextInput';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import AddButton from '../../../../components/AddButton/AddButton';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import checkboxNotChecked from '../../../../img/icons/checkbox-not-checked.svg';
import checkboxChecked from '../../../../img/icons/checkbox-checked.svg';
import EditGrammarPopupButtons from '../../../../components/EditGrammarPopupButtons/EditGrammarPopupButtons';
import DuplicateSentenceSearch from '../../../../components/DuplicateSentenceSearch/DuplicateSentenceSearch';
import { LIST_OF_LEVELS } from '../../../../constants/globalConstants';
import FKInput from '../../../../components/FKInput/FKInput';
import FKSelect from '../../../../components/FKSelect/FKSelect';
import { getFormattedTime } from '../../../../constants/globalFunctions';

const TIME_ID = uuidv4();
const LEVEL_ID = uuidv4();
const DEFAULT_RATING_ID = uuidv4();
const defaultParts = {
  questions: [
    {
      id: uuidv4(),
      type: 'questions',
      value: '',
      position: null,
    },
    {
      id: uuidv4(),
      type: 'questions',
      value: '',
      position: null,
    },
  ],
  answers: [
    {
      id: uuidv4(),
      type: 'answers',
      value: '',
      position: 1,
    },
    {
      id: uuidv4(),
      type: 'answers',
      value: '',
      position: null,
    },
  ],
  rating: 0,
};

const MAX_COUNT_UNIQUE_LETTERS = 10;
const ADDITIONAL_LETTERS_ID = uuidv4();

const NewCorrectMistake = ({ question, handleClosePopup = () => {} }) => {
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);
  const INPUT_WIDTH = 200 + Boolean(!isEditPage) * 50;
  const { pathname } = useLocation();
  const hideDuplicateSentenceSearch = pathname !== '/questions' && isEditPage;
  const { creationSuccess, levels } = useSelector(({ question }) => question);
  const { options } = useSelector(({ asideMenu }) => asideMenu);
  const [parts, setParts] = useState(question || defaultParts);
  const [topicName, setTopicName] = useState(question?.topicName);
  const [level, setLevel] = useState(question?.level || null);
  const [defaultRating, setDefaultRating] = useState(question?.defaultRating || 0);
  const [time, setTime] = useState(question?.time || 0);
  const [isAnswerInBeginning, setIsAnswerInBeginning] = useState(question?.isAnswerInBeginning);
  const [isAnswerInEnd, setIsAnswerInEnd] = useState(question?.isAnswerInEnd);
  const [errorsData, setErrorsData] = useState([]);
  const [additionalLetters, setAdditionalLetters] = useState(question?.additionalLetters?.join('') || '');
  const maxSentencesLimit = 6;
  const minSentencesLimit = 1;

  const levelRatingRange = useMemo(() => {
    if (!level) {
      return { min: 0, max: 0 };
    }

    return levels[level.value];
  }, [level, levels]);

  const validation = () => {
    const errors = [];

    parts.questions.forEach(question => {
      if (!question.value) {
        errors.push(question.id);
      }
    });

    parts.answers.forEach(answer => {
      const value = answer.value;

      if (!value || value.length > 20) {
        errors.push(answer.id);
      }
    });

    if (time < 3 || time > 180) {
      errors.push(TIME_ID);
    }

    if (!level) {
      errors.push(LEVEL_ID);
    }

    if (defaultRating < levelRatingRange.min || defaultRating > levelRatingRange.max) {
      errors.push(DEFAULT_RATING_ID);
    }

    if (errors.length > 0 || !parts.answers.length) {
      setErrorsData([...errors]);

      return false;
    }

    return true;
  };

  const resetError = id => {
    setErrorsData(prevState => prevState.filter(errorId => errorId !== id));
  };

  const listOfTopics = useMemo(() => {
    if (question) {
      const { topics } = options.find(({ topicType }) => topicType === question.topicType);

      return topics.map(({ name }) => ({ label: name, value: name }));
    }

    return [];
  }, [options, question]);

  useEffect(() => {
    if (creationSuccess) {
      setParts(defaultParts);
      setIsAnswerInBeginning(false);
      setAdditionalLetters('');
      setDefaultRating(0);
      setLevel(null);
      setTime(0);
      setIsAnswerInEnd(false);
    }
  }, [creationSuccess]);

  useEffect(() => {
    const correctVariants = parts.answers.filter(({ position }) => position === null).map(({ value }) => value.split('')).flat();
    const allLetters = correctVariants.concat(additionalLetters.split(''));
    const uniqueLetters = [...new Set(allLetters)];
    const hasError = errorsData.includes(ADDITIONAL_LETTERS_ID);

    if (hasError && uniqueLetters.length <= MAX_COUNT_UNIQUE_LETTERS) {
      resetError(ADDITIONAL_LETTERS_ID);
    }

    if (!hasError && uniqueLetters.length > MAX_COUNT_UNIQUE_LETTERS) {
      setErrorsData(prevState => [...prevState, ADDITIONAL_LETTERS_ID]);
    }

  }, [additionalLetters, parts.answers, errorsData]);

  const newPartConstructor = (type, position = null) => ({
    id: uuidv4(),
    type,
    value: '',
    position,
  });

  const handleChangePartValue = (id, value, name, partType) => {
    setParts(prevState => ({
      ...prevState,
      [partType]: prevState[partType].map(part => {
        if (part.id === id) {
          return {
            ...part,
            [name]: value.trim(),
          };
        }

        return part;
      }),
    }));
  };

  const handleAddNewPart = () => {
    const index = parts.answers.filter(answer => answer.position).length + 1;
    const newQuestion = newPartConstructor('questions');
    const newAnswer = newPartConstructor('answers', index);
    const newWrongAnswer = newPartConstructor('answers');
    const questions = [...parts.questions, newQuestion];
    const answers = [...parts.answers, newAnswer, newWrongAnswer];

    if (isAnswerInEnd) {
      const newAnswerPreInEnd = newPartConstructor('answers', index - 1);
      const newWrongAnswerPreInEnd = newPartConstructor('answers');
      const answersWithInEnd = [...parts.answers];
      answersWithInEnd.splice(parts.answers.length - 2, 0, newAnswerPreInEnd, newWrongAnswerPreInEnd);
      answersWithInEnd[answersWithInEnd.length - 2].position = index;

      return setParts(prevState => ({
        ...prevState,
        answers: answersWithInEnd,
        questions,
      }));
    }

    setParts(prevState => ({
      ...prevState,
      answers,
      questions,
    }));
  };

  const handleDeletePart = () => {
    const questions = [...parts.questions.slice(0, parts.questions.length - 1)];
    const answers = [...parts.answers.slice(0, parts.answers.length - 2)];

    if (parts.questions.length > minSentencesLimit) {
      if (isAnswerInEnd) {
        const copyAnswers = [...parts.answers];
        copyAnswers.splice(-4, 2);
        copyAnswers[copyAnswers.length - 2].position = isAnswerInBeginning
          ? (copyAnswers.length - 2) / 2
          : copyAnswers.length / 2;

        return setParts(prevState => ({
          ...prevState,
          answers: copyAnswers,
          questions,
        }));
      }

      setParts(prevState => ({
        ...prevState,
        answers,
        questions,
      }));
    }
  };

  const toggleAnswerInBeginning = () => {
    let result = [...parts.answers];
    const newAnswer = newPartConstructor('answers', 0);
    const newWrongAnswer = newPartConstructor('answers');

    if (isAnswerInBeginning) {
      result = result.slice(2);
    } else {
      result.unshift(newAnswer, newWrongAnswer);
    }

    setParts(prevState => ({
      ...prevState,
      answers: result,
    }));
    setIsAnswerInBeginning(!isAnswerInBeginning);
  };

  const toggleAnswerInEnd = () => {
    const index = parts.answers.filter(answer => answer.position).length + 1;
    let result = [...parts.answers];
    const newAnswer = newPartConstructor('answers', index);
    const newWrongAnswer = newPartConstructor('answers');

    if (isAnswerInEnd) {
      result = result.slice(0, result.length - 2);
    } else {
      result.push(newAnswer, newWrongAnswer);
    }

    setParts(prevState => ({
      ...prevState,
      answers: result,
    }));
    setIsAnswerInEnd(!isAnswerInEnd);
  };

  const sortParts = () => {
    const { questions, answers } = parts;
    const filtredAnswers = answers.filter(answer => Number.isInteger(answer.position)).sort((a, b) => a.position - b.position);
    const result = [];
    const length = Math.max(questions.length, filtredAnswers.length);

    for (let i = 0; i < length; i++) {
      if (i > questions.length + 1) {
        break;
      }

      if (i < questions.length && i < filtredAnswers.length) {
        result.push(isAnswerInBeginning ? [filtredAnswers[i], questions[i]] : [questions[i], filtredAnswers[i]]);
        continue;
      }

      if (i === questions.length && i < filtredAnswers.length && isAnswerInEnd) {
        result.push(filtredAnswers[i]);
        continue;
      }

      if (i < questions.length && i === filtredAnswers.length && !isAnswerInEnd) {
        result.push(questions[i]);
        continue;
      }
    }

    return result.flat();
  };

  const openPopup = () => {
    const rating = parts.rating;
    const questions = parts.questions.map(question => question.value);
    const splitAdditionalLetters = additionalLetters.split('');
    const preparedAnswers = [...parts.answers].sort((a, b) => !Number.isInteger(b.position) - !Number.isInteger(a.position));
    const answers = preparedAnswers.map((answer, index) => {
      if (Number.isInteger(answer.position)) {
        return {
          name: answer.value,
          ordinalRank: null,
        };
      }

      return {
        name: answer.value,
        ordinalRank: index,
      };
    });

    const requestData = {
      sentences: questions,
      answers,
      rating,
      inTheBeginning: isAnswerInBeginning,
      inTheEnd: isAnswerInEnd,
      additionalLetters: splitAdditionalLetters,
      recommendedTime: getFormattedTime(time),
      level: level.value,
      defaultRating,
      ...(isEditPage && {
        topicName: topicName.value,
        topicType: question.topicType,
      }),
    };

    if (isEditPage) {
      const { id } = question;

      dispatch(editPopupOpen(id, requestData));
    } else {
      dispatch(popupOpen(requestData));
    }
  };

  const handleSubmit = () => {
    const isFormValid = validation();

    if (isFormValid) {
      openPopup();
    }
  };

  return (
    <main className={styles.main}>
      {!hideDuplicateSentenceSearch && <DuplicateSentenceSearch type='CORRECT_MISTAKE_GRAMMAR' />}
      <section className={styles.questions}>
        <div className={styles.questions__header}>
          <h2 className={styles.questions__title}>Questions</h2>
          <label className={`${styles.answers__checkboxLabel} ${styles.questions__answerInBeginning}`}>
            <input
              type="checkbox"
              className={styles.answers__checkboxInput}
              checked={isAnswerInBeginning}
              onChange={toggleAnswerInBeginning}
            />
            <img
              src={isAnswerInBeginning ? checkboxChecked : checkboxNotChecked}
              alt="is in the begining answer checked"
            />
            <span className={styles.answers__checkboxText}>In the beginning</span>
          </label>
          <label className={`${styles.answers__checkboxLabel} ${styles.questions__answerInEnd}`}>
            <input
              type="checkbox"
              className={styles.answers__checkboxInput}
              checked={isAnswerInEnd}
              onChange={toggleAnswerInEnd}
            />
            <img
              src={isAnswerInEnd ? checkboxChecked : checkboxNotChecked}
              alt="is in the end answer checked"
            />
            <span className={styles.answers__checkboxText}>In the end</span>
          </label>
        </div>
        <div className={styles.questions__wrapper}>
          <ul className={styles.questions__list}>
            {sortParts().map(part => {
              const isError = errorsData.includes(part.id);

              return (
                <li key={part.id} className={styles.questions__item}>
                  {part.type === 'answers' &&
                  <span className={styles.questions__itemWrongWord}>Wrong word</span>
                  }
                  <TextInput
                    isError={isError}
                    resetError={resetError}
                    part={part}
                    setValue={handleChangePartValue}
                    resetValue={creationSuccess}
                  />
                </li>
              );
            })}
          </ul>
          <DeleteButton
            disabled={parts.questions.length <= minSentencesLimit}
            onClick={handleDeletePart}
          />
          <AddButton
            disabled={parts.questions.length >= maxSentencesLimit}
            onClick={handleAddNewPart}
          />
        </div>
      </section>
      <section className={styles.correctAnswers}>
        <div className={styles.correctAnswers__header}>
          <h2 className={styles.correctAnswers__title}>Correct Answer/s</h2>
        </div>
        <div className={styles.correctAnswers__wrapper}>
          <ul className={styles.correctAnswers__list}>
            {parts.answers
            .filter(part => !Number.isInteger(part.position))
            .map(part => {
              const isError = errorsData.includes(part.id);

              return (
                <li key={part.id} className={styles.correctAnswers__item}>
                  <TextInput
                    isError={isError}
                    resetError={resetError}
                    part={part}
                    setValue={handleChangePartValue}
                    resetValue={creationSuccess} />
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      <section>
        <div className={styles.preview__header}>
          <h2 className={styles.preview__title}>Additional letters</h2>
        </div>
        <input
          className={styles.additionalLettersInput}
          style={{ borderColor: errorsData.includes(ADDITIONAL_LETTERS_ID) ? 'red' : 'black' }}
          value={additionalLetters}
          onChange={({ target: { value } }) => setAdditionalLetters(value)}
          onFocus={() => resetError(ADDITIONAL_LETTERS_ID)}
        />
        {errorsData.includes(ADDITIONAL_LETTERS_ID) && (
          <p className={styles.errorMessage}>Answers must be more than {MAX_COUNT_UNIQUE_LETTERS} unique letters</p>
        )}
      </section>
      <section className={styles.additionalSettings}>
        {isEditPage && (
          <div style={{ marginRight: 50 }}>
            <h2>Topic</h2>
            <FKSelect
              form={{ errors: {}, touched: true, setFieldValue: (_, value) => setTopicName(value) }}
              field={{ name: 'topicName', value: topicName }}
              options={listOfTopics}
              placeholder='Select your topic'
              width={INPUT_WIDTH}
            />
          </div>
        )}
        <div>
          <h2>Time (s)</h2>
          <FKInput
            form={{ errors: {}, touched: true }}
            field={{ name: 'time' }}
            type='number'
            placeholder='Enter your time'
            style={{ borderColor: errorsData.includes(TIME_ID) ? 'red' : 'black' }}
            value={time}
            width={INPUT_WIDTH}
            onChange={({ target: { value } }) => setTime(value)}
            onFocus={() => resetError(TIME_ID)}
          />
        </div>
        <div style={{ marginLeft: 50 }}>
          <h2>Level</h2>
          <FKSelect
            form={{ errors: {}, touched: true, setFieldValue: (_, value) => setLevel(value) }}
            field={{ name: 'level', value: level }}
            borderColor={errorsData.includes(LEVEL_ID) ? 'red' : 'black'}
            options={LIST_OF_LEVELS}
            placeholder='Select your level'
            width={INPUT_WIDTH}
            onFocus={() => resetError(LEVEL_ID)}
          />
        </div>
        <div style={{ marginLeft: 50, opacity: level ? 1 : 0.5 }}>
          <h2>Default rating ({levelRatingRange.min} - {levelRatingRange.max})</h2>
          <FKInput
            form={{ errors: {}, touched: true }}
            field={{ name: 'default_rating' }}
            type='number'
            min={levelRatingRange.min}
            max={levelRatingRange.max}
            placeholder='Enter your default rating'
            disabled={!level}
            style={{ borderColor: errorsData.includes(DEFAULT_RATING_ID) ? 'red' : 'black' }}
            value={defaultRating}
            width={INPUT_WIDTH}
            onChange={({ target: { value } }) => setDefaultRating(value)}
            onFocus={() => resetError(DEFAULT_RATING_ID)}
          />
        </div>
      </section>
      <section className={styles.preview}>
        <div className={styles.preview__header}>
          <h2 className={styles.preview__title}>Preview</h2>
        </div>
        <ul className={styles.preview__list}>
          {sortParts().map(part => (
            <li
              key={part.id}
              className={cn(
                `${styles.preview__item}`,
                { [`${styles.preview__question}`]: part.type === 'questions' },
                { [`${styles.preview__answer}`]: part.type === 'answers' }
              )}
            >
              {part.value}
            </li>
          ))}
        </ul>
      </section>
      {isEditPage ? (
        <EditGrammarPopupButtons handleClose={handleClosePopup} handleSubmit={handleSubmit} />
      ) : (
        <SaveButton saveSentence={handleSubmit} />
      )}
    </main>
  );
};

export default NewCorrectMistake;
