import {
  SIGNIN_USER_REQUEST,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
} from '../constants/';

const INIT_STATE = {
  token: Boolean(localStorage.getItem('englishAdminToken')),
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case SIGNIN_USER_SUCCESS:
      return { ...state, token: true, loading: false };
    case SIGNIN_USER_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case LOGOUT_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGOUT_USER_SUCCESS:
      return { ...state, token: null, loading: false };
    case LOGOUT_USER_FAILURE:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};
