import React, { useCallback } from 'react';
import { getIn } from 'formik';
import { StyledDropZone } from 'react-drop-zone';

import styles from './FKMediaUploader.module.scss';

const FKMediaUploader = obj => {
  const { field, form, accept = 'image/*', maxSize = 1000000, ...props } = obj;
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  const onDrop = useCallback(
    async file => {
      const { setFieldValue } = form;

      if (file.size < maxSize) {
        await setFieldValue(field.name, file);
      } else {
        alert('File is too big!');
      }
    },
    [maxSize, field.name, form],
  );

  return (
    <StyledDropZone {...field} {...props} accept={accept} onDrop={onDrop}>
      <div
        className={styles.dndContainer}
        style={{
          borderColor: touch && error ? '#E51A4B' : '#BBB',
          height: props.height,
          width: props.width,
        }}
      >
        {field?.value?.name || (
          <>
            <span className={styles.dndTitle}>DRAG & DROP</span>
            <img src={require('../../img/dnd.svg')} alt='Drag and Drop' />
          </>
        )}
      </div>
    </StyledDropZone>
  );
};

export default FKMediaUploader;
