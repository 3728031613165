import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { popupClose, editQuestion, deleteQuestion, closeEditModal } from '../../store/actions';
import cn from 'classnames';
import styles from './ListOfQuestionsPopup.module.scss';

import Spinner from '../Spinner/Spinner';

const ListOfQuestionsPopup = () => {
  const { selectedId, isEditingPopupOpen, data, creationSuccess, error: questionError, loading } = useSelector(({ question }) => question);
  const { error: tasksError } = useSelector(({ tasks }) => tasks);
  const isDeleteRequest = Object.keys(data).length === 0;
  const dispatch = useDispatch();
  const closePopup = () => dispatch(popupClose());
  const confirm = () => {
    if (isDeleteRequest) {
      dispatch(deleteQuestion(selectedId));
    } else {
      const questionRequest = dispatch(editQuestion(selectedId, { ...data }));

      if (questionRequest) {
        dispatch(closeEditModal());
      }
    }
  };
  const error = questionError || tasksError;

  return (
    isEditingPopupOpen && (
      <div className={styles.popup__background}>
        <div className={styles.popup}>
          {loading
            ? <Spinner />
            : <>
              <h2 className={styles.popup__title}>
                {error
                  ? "Ooops"
                  : creationSuccess
                    ? "Congratulations"
                    : "Are you sure?"
                }
              </h2>
              <button type="button" onClick={closePopup} className={styles.popup__closeButton} />
              <p className={cn(`${styles.popup__text}`,
                { [`${styles.popup__error}`]: error },
                { [`${styles.popup__success}`]: creationSuccess }
              )}>
                {error
                  ? "Something went wrong please try again"
                  : creationSuccess
                    ? isDeleteRequest
                      ? "Sentence deleted successfully!"
                      : "Sentence approved successfully!"
                    : isDeleteRequest
                      ? "By clicking “Yes” you confirm deleting a question in the system"
                      : "By clicking “Yes” you confirm approving a question in the system"
                }
              </p>
              {!creationSuccess &&
                <div className={styles.popup__controls}>
                  <button type="button" onClick={closePopup} className={styles.popup__cancel}>
                    Cancel
                </button>
                  <button type="button" onClick={confirm} className={styles.popup__confirm}>
                    {error ? "Try again" : "Yes"}
                  </button>
                </div>
              }
            </>
          }
        </div>
      </div>
    )
  );
};

export default ListOfQuestionsPopup;
