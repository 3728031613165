import React, { useCallback, useMemo } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import styles from './ListeningMultipleChoice.module.scss';
import { BASE_URL, RATING_MAX, RATING_MIN } from '../../../../constants/globalConstants';
import FKMediaUploader from '../../../../components/FKMediaUploader/FKMediaUploader';
import FKInput from '../../../../components/FKInput/FKInput';
import SquareSelect from '../../../../components/SquareSelect/SquareSelect';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import AddButton from '../../../../components/AddButton/AddButton';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import FkCheckbox from '../../../../components/FKCheckbox/FKCheckbox';
import { editPopupOpen, popupOpen } from '../../../../store/actions';
import EditPopupButtons from '../../../../components/EditPopupButtons/EditPopupButtons';

const ListeningMultipleChoice = ({ question, handleClosePopup = () => {} }) => {
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      audio: Yup.mixed().when('isEditPage', {
        is: false,
        then: Yup.mixed().required(),
      }),
      sentences: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(),
        }),
      ),
      answers: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(),
        }),
      ),
      rating: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .required(),
    });
  }, []);

  const initialState = {
    isEditPage: false,
    sentences: [
      { name: '', id: uuid() },
      { name: '', id: uuid() },
    ],
    audio: null,
    answers: [
      { name: '', id: uuid(), ordinalRank: null },
      { name: '', id: uuid(), ordinalRank: null },
      { name: '', id: uuid(), ordinalRank: null },
    ],
    inTheBeginningAnswer: { name: '', id: uuid(), ordinalRank: 0 },
    inTheEndAnswer: { name: '', id: uuid(), ordinalRank: 7 },
    inTheBeginning: false,
    inTheEnd: false,
    rating: '',
  };

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const requestSentences = values.sentences.map(({ name }) => name.trim());
      const trimAnswers = values.answers.map(answer => ({ ...answer, name: answer.name.trim() }));
      const inTheBeginningTrimAnswers = { ...values.inTheBeginningAnswer, name: values.inTheBeginningAnswer?.name?.trim() };
      const inTheEndTrimAnswers = { ...values.inTheEndAnswer, name: values.inTheEndAnswer?.name?.trim() };
      const answerList = [
        values.inTheBeginning ? inTheBeginningTrimAnswers : null,
        ...trimAnswers,
        values.inTheEnd ? inTheEndTrimAnswers : null,
      ].filter(item => item);

      const requestData = {
        sentences: requestSentences,
        answers: answerList,
        inTheBeginning: values.inTheBeginning,
        inTheEnd: values.inTheEnd,
        rating: values.rating,
        file: values.audio,
      };

      if (isEditPage) {
        const { id } = question;

        dispatch(editPopupOpen(id, requestData));
      } else {
        dispatch(popupOpen(requestData, resetForm));
      }

      setSubmitting(false);
    },
    [dispatch, isEditPage, question],
  );

  return (
    <Formik initialValues={question || initialState} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>
      {({ values, setFieldValue }) => {
        const fileUrl = `${BASE_URL}/file/${question?.fileId}`;
        const disabledAddBtn = values.answers.length >= 4;
        const preview = values.audio && URL.createObjectURL(values.audio);
        const audioPreview = isEditPage ? fileUrl : preview;
        const answerWithSolution = values.answers
          .filter(({ ordinalRank }) => ordinalRank)
          .sort((a, b) => (a.ordinalRank > b.ordinalRank ? 1 : a.ordinalRank < b.ordinalRank ? -1 : 0));
        const optionList = new Array(values.sentences.length - 1).fill('').map((_, index) => index + 1);
        const selectOptionList = answerWithSolution.length <= 1 ? optionList : ['None', ...optionList];

        const addNewAnswer = arrayHelpers => {
          if (values.answers.length < 4) {
            arrayHelpers.push({ name: '', id: uuid(), ordinalRank: null });
          }
        };

        const handleChangeAnswer = (i, answer) => {
          const answerValue = answer === 'None' ? null : answer;

          values.answers.forEach((item, index) => {
            if (item.ordinalRank === answer) {
              setFieldValue(`answers.${index}.ordinalRank`, null);
            }
          });

          setFieldValue(`answers.${i}.ordinalRank`, answerValue);
        };

        return (
          <Form>
            {!isEditPage && (
              <section className={styles.questionWrapper}>
                <h3 className={styles.title}>File (audio):</h3>
                <Field name='audio' accept='audio/*' maxSize={100000000} component={FKMediaUploader} width='50%' />
              </section>
            )}
            <section className={styles.questionWrapper}>
              <div className={styles.questionTitleContainer}>
                <h3 className={styles.title}>Question</h3>
                <div className={styles.checkboxContainer}>
                  <FkCheckbox
                    id='inTheBeginning'
                    label='In the beginning'
                    value={values.inTheBeginning}
                    onChange={({ target }) => setFieldValue('inTheBeginning', target.checked)}
                  />
                  <FkCheckbox
                    id='inTheEnd'
                    onChange={({ target }) => setFieldValue('inTheEnd', target.checked)}
                    value={values.inTheEnd}
                    label='In the end'
                  />
                </div>
              </div>
              <FieldArray name='sentences'>
                {arrayHelpers =>
                  values.sentences.map((question, i) => {
                    const disabledAddBtn = values.sentences.length >= 5;
                    const disabledDeleteBtn = values.sentences.length <= 1;
                    const showAddBtn = i === values.sentences.length - 1;

                    const handleAddQuestion = () => {
                      arrayHelpers.push({ name: '', id: uuid() });
                    };

                    return (
                      <div key={question.id} className={styles.questionContainer}>
                        <Field name={`sentences.${i}.name`} component={FKInput} placeholder='Enter your question' width='60%' />
                        <DeleteButton disabled={disabledDeleteBtn} onClick={() => arrayHelpers.remove(i)} />
                        {showAddBtn && <AddButton disabled={disabledAddBtn} onClick={handleAddQuestion} />}
                      </div>
                    );
                  })
                }
              </FieldArray>
            </section>
            <section className={styles.answerWrapper}>
              <h3 className={styles.title}>Answers</h3>
              <FieldArray name='answers'>
                {arrayHelpers => (
                  <div>
                    <div className={styles.fieldsContainer}>
                      {values.inTheBeginning && (
                        <div className={styles.answerContainer}>
                          <div className={styles.answer}>
                            <Field name={`inTheBeginningAnswer.name`} component={FKInput} placeholder='Enter your answer' width='100%' />
                            <SquareSelect optionsList={[]} position={0} />
                            <DeleteButton disabled />
                          </div>
                        </div>
                      )}
                      {values.answers.map((answer, i) => {
                        const disabledDeleteBtn = values.answers.length < 2 || answer.ordinalRank;

                        return (
                          <div className={styles.answerContainer} key={answer.id}>
                            <div className={styles.answer}>
                              <Field name={`answers.${i}.name`} component={FKInput} placeholder='Enter your answer' width='100%' />
                              <SquareSelect
                                optionsList={selectOptionList}
                                handleChange={(_, index) => handleChangeAnswer(i, index)}
                                position={answer.ordinalRank}
                              />
                              <DeleteButton disabled={disabledDeleteBtn} onClick={() => arrayHelpers.remove(i)} />
                            </div>
                          </div>
                        );
                      })}
                      {values.inTheEnd && (
                        <div className={styles.answerContainer}>
                          <div className={styles.answer}>
                            <Field name={`inTheEndAnswer.name`} component={FKInput} placeholder='Enter your answer' width='100%' />
                            <SquareSelect optionsList={[]} position={7} />
                            <DeleteButton disabled />
                          </div>
                        </div>
                      )}
                    </div>
                    <AddButton disabled={disabledAddBtn} onClick={() => addNewAnswer(arrayHelpers)} />
                  </div>
                )}
              </FieldArray>
            </section>
            <section className={styles.ratingContainer}>
              <h3 className={styles.title}>Approximate rating</h3>
              <Field name='rating' type='number' component={FKInput} placeholder='Enter question rating' width='25%' />
            </section>
            <section>
              <h3 className={styles.title}>Preview</h3>
              <div className={styles.previewContainer}>
                <div className={styles.audioContainer}>
                  <audio controls src={audioPreview}>
                    <p>
                      Your browser does not support HTML5 audio.
                      <a href={audioPreview}>{values.audio?.name}</a>
                    </p>
                  </audio>
                </div>
                <div className={styles.preview}>
                  <div className={styles.previewAnswerWrapper}>
                    {values.inTheBeginning && <div className={styles.solutionAnswer}>{values.inTheBeginningAnswer?.name}</div>}
                    {values.sentences.map(({ name }, index) => {
                      const isLastSentence = index === values.sentences.length - 1;
                      const solution = values.answers.find(({ ordinalRank }) => ordinalRank === index + 1);

                      return (
                        <div className={styles.solutionContainer}>
                          <div>{name}</div>
                          {!isLastSentence && <div className={styles.solutionAnswer}>{solution?.name}</div>}
                        </div>
                      );
                    })}
                    {values.inTheEnd && <div className={styles.solutionAnswer}>{values.inTheEndAnswer?.name}</div>}
                  </div>
                </div>
              </div>
            </section>
            {isEditPage ? <EditPopupButtons handleClose={handleClosePopup} /> : <SaveButton type='submit' />}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ListeningMultipleChoice;
