import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, refreshTokenRequest } from './store/actions';

const AxiosConfig = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useSelector(({ auth }) => auth);
  let isTokenRefreshing = false;
  let refreshSubscribers = [];

  const onAccessTokenFetched = accessToken => {
    refreshSubscribers = refreshSubscribers.filter(callback => callback(accessToken));
  };

  const addSubscriber = callback => refreshSubscribers.push(callback);

  // setting axios defaults
  axios.defaults.baseURL = 'https://admin.english.impltech.com/api/';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('englishAdminToken');

  // Add a request interceptor
  /*  axios.interceptors.request.use(function(config) {
      // Do something before request is sent
      return config;
    }, function(error) {
      // Do something with request error
      return Promise.reject(error);
    });*/

  // Add a response interceptor
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (status === 401) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;
          const refreshToken = localStorage.getItem('englishAdminRefreshToken');
          if (refreshToken) {
            refreshTokenRequest(refreshToken).then(newAccessToken => {
              isTokenRefreshing = false;
              axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
              onAccessTokenFetched(newAccessToken);
              refreshSubscribers = [];
            });
          }
        }

        if (config.url === '/token/refresh') {
          console.log(error.response);
          token && dispatch(logoutUser());
          history.push('/signin');
        }

        const retryOriginalRequest = new Promise(resolve => {
          addSubscriber(accessToken => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      } else {
        return Promise.reject(error);
      }
    },
  );
};

export default AxiosConfig;
