import axios from 'axios';
import { SET_TASKS, SET_TASKS_ERROR, SET_TASKS_MODAL_STATE, TURN_ON_TASKS_LOADER } from '../constants';
import { v4 as uuidv4 } from 'uuid';

export const turnOnTasksLoader = () => ({ type: TURN_ON_TASKS_LOADER });
export const setTasksError = error => ({ type: SET_TASKS_ERROR, payload: error });
export const setTasksModalState = bool => ({ type: SET_TASKS_MODAL_STATE, payload: bool });

export const setTasks = (page, id, rating, defaultRating, level, category, type, topic) => dispatch => {
  dispatch(turnOnTasksLoader());

  axios
    .post(
      'question/filter',
      { rating, id, defaultRating, level, status: 'APPROVED', category: category?.toUpperCase(), type, topic },
      { params: { size: 10, page, sort: 'rating,asc' } },
    )
    .then(({ data }) => {
      const tasks = data.content.map(task => {
        const answers = task.answers.map(answer => ({
          id: uuidv4(),
          value: answer.name,
          position: task.sentence.startsWith('_') ? answer.ordinalRank : Number.isInteger(answer.ordinalRank) ? answer.ordinalRank + 1 : null,
          type: 'answers',
        }));

        return {
          ...task,
          answers,
        };
      });
      const payloadData = {
        tasks: tasks,
        pagination: {
          currentPage: data.pageable.pageNumber,
          totalPages: data.totalPages,
        },
      };

      dispatch({ type: SET_TASKS, payload: payloadData });
    })
    .catch(error => {
      dispatch(setTasksError(error));
    });
};
