import {
  EDIT_DICTIONARY_WORD,
  RESET_DICTIONARY_ERROR,
  SET_ACTIVATE_DICTIONARY_LOADER,
  SET_DICTIONARY_CATEGORIES,
  SET_DICTIONARY_ERROR,
  SET_DICTIONARY_LEVELS,
  SET_DICTIONARY_WORDS,
} from '../constants';
import axios from 'axios';

export const resetDictionaryError = () => ({ type: RESET_DICTIONARY_ERROR });

export const setDictionaryLevels = () => dispatch => {
  dispatch({ type: SET_ACTIVATE_DICTIONARY_LOADER });

  axios
    .get('/vocabulary/levels')
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: SET_DICTIONARY_LEVELS, payload: res.data });
      }
    })
    .catch(error => {
      dispatch({ type: SET_DICTIONARY_ERROR, payload: error });
    });
};

export const setDictionaryCategoriesByLevelId = levelId => dispatch => {
  dispatch({ type: SET_ACTIVATE_DICTIONARY_LOADER });

  axios
    .get(`/vocabulary/categories?level=${levelId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: SET_DICTIONARY_CATEGORIES, payload: res.data });
      }
    })
    .catch(error => {
      dispatch({ type: SET_DICTIONARY_ERROR, payload: error });
    });
};

export const setDictionaryWords = (category, level, page, size, word) => dispatch => {
  dispatch({ type: SET_ACTIVATE_DICTIONARY_LOADER });

  axios
    .get(`/vocabulary/words?category=${category}&level=${level}&page=${page}&size=${size}&word=${word}&sort=createdAt,desc`)
    .then(res => {
      if (res.status === 200) {
        const data = {
          content: res.data.content,
          totalPages: res.data.totalPages,
          totalElements: res.data.totalElements,
        };
        dispatch({ type: SET_DICTIONARY_WORDS, payload: data });
      }
    })
    .catch(error => {
      dispatch({ type: SET_DICTIONARY_ERROR, payload: error });
    });
};

export const createDictionaryNewWord = (requestData, category, level, page, size, word) => dispatch => {
  dispatch({ type: SET_ACTIVATE_DICTIONARY_LOADER });

  axios
    .post('/vocabulary/words', requestData)
    .then(res => {
      if (res.status === 200) {
        dispatch(setDictionaryWords(category, level, page, size, word));
      }
    })
    .catch(error => {
      dispatch({ type: SET_DICTIONARY_ERROR, payload: error });
    });
};

export const deleteDictionaryWord = (id, category, level, page, size, word) => dispatch => {
  dispatch({ type: SET_ACTIVATE_DICTIONARY_LOADER });

  axios
    .delete(`/vocabulary/words/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setDictionaryWords(category, level, page, size, word));
      }
    })
    .catch(error => {
      dispatch({ type: SET_DICTIONARY_ERROR, payload: error });
    });
};

export const editDictionaryWord = (requestData, wordId) => dispatch => {
  dispatch({ type: SET_ACTIVATE_DICTIONARY_LOADER });

  axios
    .post(`/vocabulary/words/${wordId}`, requestData)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: EDIT_DICTIONARY_WORD, payload: res.data });
      }
    })
    .catch(error => {
      dispatch({ type: SET_DICTIONARY_ERROR, payload: error });
    });
};
