import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { v4 as uuidv4, v4 as uuid } from 'uuid';

import styles from './EditQuestionPopup.module.scss';
import VocabularyDefinition from '../../pages/AddQuestion/Vocabulary/VocabularyDefinition/VocabularyDefinition';
import VocabularyGapFill from '../../pages/AddQuestion/Vocabulary/VocabularyGapFill/VocabularyGapFill';
import VocabularyGroup from '../../pages/AddQuestion/Grammar/GrammarGroup/GrammarGroup';
import VocabularyPictureArrows from '../../pages/AddQuestion/Vocabulary/VocabularyPictureArrows/VocabularyPictureArrows';
import VocabularyPictureMeaning from '../../pages/AddQuestion/Vocabulary/VocabularyPictureMeaning/VocabularyPictureMeaning';
import VocabularySpelling from '../../pages/AddQuestion/Vocabulary/VocabularySpelling/VocabularySpelling';
import ListeningMultipleChoice from '../../pages/AddQuestion/Listening/ListeningMultipleChoice/ListeningMultipleChoice';
import ListeningWordOrder from '../../pages/AddQuestion/Listening/ListeningWordOrder/ListeningWordOrder';
import ListeningTrueFalse from '../../pages/AddQuestion/Listening/ListeningTrueFalse/ListeningTrueFalse';
import ListeningVideo from '../../pages/AddQuestion/Listening/ListeningVideo/ListeningVideo';
import NewSubstitution from '../../pages/AddQuestion/Grammar/NewSubstitution/NewSubstitution';
import NewWordOrder from '../../pages/AddQuestion/Grammar/NewWordOrder/NewWordOrder';
import NewCorrectMistake from '../../pages/AddQuestion/Grammar/NewCorrectMistake/NewCorrectMistake';
import { getSecondsByTime } from '../../constants/globalFunctions';
import ListeningLetterOrder from '../../pages/AddQuestion/Listening/ListeningLetterOrder/ListeningLetterOrder';

const EditQuestionPopup = ({ question, modalIsOpen, handleCloseModal }) => {
  const modalPath = useMemo(() => {
    const { topicType, topicName, questionType } = question;
    const topic = topicType?.toLowerCase();
    const splitQuestion = questionType?.split('_');
    const typeOfQuestion = splitQuestion
      ?.filter((_, index) => index !== splitQuestion.length - 1)
      .join(' ')
      ?.toLowerCase();

    return `${topic} > ${typeOfQuestion} > ${topicName}`;
  }, [question]);

  const questionPage = useCallback(
    question => {
      const { questionType } = question;

      switch (true) {
        case questionType === 'DEFINITION_VOCABULARY': {
          const answers = question.answers.map(({ id, position, value }) => ({
            id,
            name: value,
            ordinalRank: position ? position - 1 : position,
          }));
          const sentences = [
            {
              sentence: question.sentence
                .replaceAll('_', '')
                .replaceAll('  ', ' ')
                .trim(),
              id: uuid(),
            },
          ];

          const pageData = {
            ...question,
            answers,
            sentences,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <VocabularyDefinition handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'GAP_FILL_VOCABULARY': {
          const splitQuestionBySpace = question.sentence.split('_').filter(item => item);
          const splitQuestionByUnderscore = question.sentence.split(' ');
          const inTheBeginning = splitQuestionByUnderscore[0] === '_';
          const inTheEnd = splitQuestionByUnderscore.reverse()[0] === '_';
          const sentences = splitQuestionBySpace.map(item => ({ id: uuidv4(), sentence: item.trim() }));
          const joinAnswer = question.answers.map(({ value }) => value).join('');
          const answers = [
            {
              name: joinAnswer,
              id: uuid(),
              ordinalRank: null,
            },
          ];

          const pageData = {
            ...question,
            answers,
            sentences,
            inTheBeginning,
            inTheEnd,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <VocabularyGapFill handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'GROUP_VOCABULARY' || questionType === 'GROUP_GRAMMAR': {
          const initialAnswers = question.answers.map(({ id, position, value }) => ({
            id,
            name: value,
            ordinalRank: position ? position - 1 : position,
          }));
          const splitSentence = question.sentence.split('/');
          const answers = splitSentence.map((_, index) => initialAnswers.filter(({ ordinalRank }) => index + 1 === ordinalRank));
          const sentences = splitSentence.map(name => ({ id: uuidv4(), sentence: name }));
          const pageData = {
            ...question,
            answers,
            sentences,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <VocabularyGroup handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'PICTURE_ARROWS_VOCABULARY': {
          const answers = question.answers.map(({ id, position, value }) => ({
            id,
            name: value,
            ordinalRank: position ? position - 1 : position,
          }));
          const sentences = [question.sentence];
          const pageData = {
            ...question,
            answers,
            sentences,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <VocabularyPictureArrows handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'PICTURE_VOCABULARY': {
          const answers = question.answers.map(({ id, position, value }) => ({
            id,
            name: value,
            ordinalRank: position ? position - 1 : position,
          }));
          const sentences = [question.sentence];
          const pageData = {
            ...question,
            answers,
            sentences,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <VocabularyPictureMeaning handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'PICTURE_SPELLING_VOCABULARY': {
          const answers = question.answers.map(({ id, position, value }) => ({
            id,
            name: value,
            ordinalRank: position ? position - 1 : position,
          }));
          const sentences = [question.sentence];
          const pageData = {
            ...question,
            answers,
            sentences,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <VocabularySpelling handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'MULTIPLE_CHOICE_LISTENING' || questionType === 'GAP_FILL_LISTENING': {
          const inTheBeginning = question.sentence[0] === '_';
          const inTheEnd = question.sentence[question.sentence.length - 1] === '_';
          const answers = question.answers
            .filter(({ position }) => position !== 0 && position !== 7 + Number(inTheEnd) - Number(inTheBeginning))
            .map(({ id, position, value }) => ({
              id,
              name: value,
              ordinalRank: position && !inTheBeginning ? position - 1 : position,
            }));
          const splitQuestion = question.sentence.split('_').filter(item => item);
          const sentences = splitQuestion.map(item => ({ id: uuidv4(), name: item.trim() }));
          let inTheBeginningAnswer,
            inTheEndAnswer = {};

          if (inTheBeginning) {
            const answer = question.answers.find(({ position }) => position === 0);
            inTheBeginningAnswer = {
              id: answer.id,
              name: answer.value,
              ordinalRank: answer.position,
            };
          }
          if (inTheEnd) {
            const answer = question.answers.find(({ position }) => position === 7 + Number(inTheEnd) - Number(inTheBeginning));
            inTheEndAnswer = {
              id: uuidv4(),
              name: answer?.value,
              ordinalRank: answer?.position,
            };
          }

          const pageData = {
            ...question,
            answers,
            sentences,
            inTheBeginning,
            inTheEnd,
            inTheBeginningAnswer,
            inTheEndAnswer,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <ListeningMultipleChoice handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'WORD_ORDER_LISTENING': {
          const sentences = question.questions.map(({ id, value }) => ({ id, name: value }));
          const pageData = {
            ...question,
            sentences,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <ListeningWordOrder handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'TRUE_OR_FALSE_LISTENING': {
          const answers = question.answers.map(({ id, position, value }) => ({
            id,
            name: { value, label: value },
            ordinalRank: position,
          }));
          const sentences = question.questions.map(({ id, value }) => ({ id, name: value }));
          const pageData = {
            ...question,
            sentences,
            answers,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <ListeningTrueFalse handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'VIDEO_LISTENING': {
          const inTheEnd = question.answers.find(({ position }) => position === 8);
          const inTheBeginning = question.answers.find(({ position }) => position === 1);
          const answers = question.answers
            .filter(({ position }) => position !== 1 && position !== 8)
            .map(({ id, position, value }) => ({
              id,
              name: value,
              ordinalRank: position && !inTheBeginning ? position - 1 : position,
            }));
          const splitQuestion = question.sentence.split('_').filter(item => item);
          const sentences = splitQuestion.map(item => ({ id: uuidv4(), name: item.trim() }));
          const inTheBeginningAnswer = {
            id: uuidv4(),
            name: inTheBeginning?.value || '',
            ordinalRank: inTheBeginning?.position || null,
          };
          const inTheEndAnswer = {
            id: uuidv4(),
            name: inTheEnd?.value || '',
            ordinalRank: inTheEnd?.position || null,
          };

          const pageData = {
            ...question,
            answers,
            sentences,
            inTheBeginning,
            inTheEnd,
            inTheBeginningAnswer,
            inTheEndAnswer,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <ListeningVideo handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'LETTER_ORDER_LISTENING': {
          const sentences = [{ id: uuidv4(), sentence: question.sentence }];
          const pageData = {
            ...question,
            sentences,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <ListeningLetterOrder handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'SUBSTITUTION_GRAMMAR' || questionType === 'SUBSTITUTION_VOCABULARY': {
          const splitQuestion = question.sentence.split('_').filter(item => item);
          const questions = splitQuestion.map(item => ({
            id: uuidv4(),
            type: 'questions',
            value: item.trim(),
            position: null,
          }));
          const isAnswerInBeginning = question.sentence[0] === '_';
          const isAnswerInEnd = question.sentence[question.sentence.length - 1] === '_';

          const pageData = {
            ...question,
            questions,
            isAnswerInBeginning,
            isAnswerInEnd,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <NewSubstitution handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'WORD_ORDER_GRAMMAR': {
          const words = question?.questions?.map(({ id, value }) => ({
            id,
            type: 'words',
            value,
          }));

          const pageData = {
            ...question,
            words: words || question.answers,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <NewWordOrder handleClosePopup={handleCloseModal} question={pageData} />;
        }

        case questionType === 'CORRECT_MISTAKE_GRAMMAR': {
          const splitQuestion = question.sentence.split('_').filter(item => item);
          const questions = splitQuestion.map(item => ({
            id: uuidv4(),
            type: 'questions',
            value: item.trim(),
            position: null,
          }));
          const incorrectList = question.answers.filter(({ position }) => Number.isInteger(position));
          const correctList = question.answers.filter(({ position }) => position === null);
          const newIncorrectList = incorrectList.map(item => ({ ...item, position: null }));
          const newCorrectList = correctList.map((item, index) => ({ ...item, position: incorrectList[index].position }));
          const answers = newIncorrectList.map((item, index) => [item, newCorrectList[index]]).flat();
          const isAnswerInBeginning = question.sentence[0] === '_';
          const isAnswerInEnd = question.sentence[question.sentence.length - 1] === '_';

          const pageData = {
            ...question,
            questions,
            answers: answers,
            isAnswerInBeginning,
            isAnswerInEnd,
            time: getSecondsByTime(question.recommendedTime),
            level: { label: question.level, value: question.level },
            topicName: { label: question.topicName, value: question.topicName },
          };

          return <NewCorrectMistake handleClosePopup={handleCloseModal} question={pageData} />;
        }

        default:
          return null;
      }
    },
    [handleCloseModal],
  );

  const customStyles = {
    overlay: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.52)',
    },
    content: {
      height: '80vh',
      width: '80vw',
      border: 'none',
      borderRadius: 0,
      padding: 0,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflowY: 'scroll',
    },
  };

  return (
    <Modal isOpen={modalIsOpen} onAfterOpen={() => {}} ariaHideApp={false} onRequestClose={handleCloseModal} style={customStyles}>
      <header className={styles.modalHeader}>
        <span className={styles.id}>ID: {question.id}</span>
        <span className={styles.path}>{modalPath}</span>
      </header>
      <section className={styles.contentContainer}>{questionPage(question)}</section>
    </Modal>
  );
};

export default EditQuestionPopup;
