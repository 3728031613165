import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from '../../../img/plus.svg';

import styles from './Categories.module.scss';
import { setDictionaryCategoriesByLevelId } from '../../../store/actions/Dictionary';
import Spinner from '../../../components/Spinner/Spinner';

const Categories = () => {
  const { levelId } = useParams();

  const dispatch = useDispatch();
  const { categories, loading } = useSelector(({ dictionary }) => dictionary);

  const level = useMemo(() => {
    const replaceLevel = levelId.replaceAll('_', ' ');

    return replaceLevel[0].toUpperCase() + replaceLevel.slice(1).toLowerCase();
  }, [levelId]);

  useEffect(() => {
    dispatch(setDictionaryCategoriesByLevelId(levelId));
  }, [dispatch, levelId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.breadcrumbs}>
        <Link to='/dictionary/levels' className={styles.breadcrumbsItem}>
          Dictionary
        </Link>
        <span className={styles.breadcrumbsItem}> &gt; </span>
        <span className={styles.breadcrumbsCurrentItem}>Level: {level}</span>
      </div>
      <div className={styles.categoriesContainer}>
        {categories.map(({ name, category, words }) => (
          <Link to={`/dictionary/levels/${levelId}/categories/${category}/words`} className={styles.categoryItem} key={category}>
            <span className={styles.categoryItemText}>{name}</span>
            <div className={styles.wordsContainer}>
              <span className={styles.categoryItemText} style={{ marginRight: 25, fontWeight: 'bold' }}>
                {words}
              </span>
              <PlusIcon />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Categories;
