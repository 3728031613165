import {
  GET_MENU_OPTIONS_REQUEST,
  GET_MENU_OPTIONS_SUCCESS,
  GET_MENU_OPTIONS_FAILURE,
  SET_MENU_OPTIONS,
  SET_TOPICS_AND_TYPES,
  SET_CURRENT_MENU_OPTIONS,
  SET_MENU_CURRENT_LEVEL,
} from '../constants/';
import axios from 'axios';

export const setTopicsAndTypes = (topicType, topics, types) => dispatch => {
  dispatch({ type: SET_TOPICS_AND_TYPES, payload: { types: types, topics: topics } });
  dispatch({ type: SET_MENU_OPTIONS, payload: [topicType, topics[0], types[0]] });
};

export const setCurrentMenuOptions = data => ({ type: SET_CURRENT_MENU_OPTIONS, payload: data });
export const setMenuCurrentLevel = level => ({ type: SET_MENU_CURRENT_LEVEL, payload: level });

export const getAsideMenuOptions = () => dispatch => {
  dispatch({ type: GET_MENU_OPTIONS_REQUEST });
  axios
    .get('/topic')
    .then(res => {
      console.log(res);
      if (res.status === 200) {
        const menuData = [{ name: res.data[0].topicTypeName, topicType: res.data[0].topicType }, res.data[0].topics[0], res.data[0].questionTypes[0]];

        dispatch({ type: GET_MENU_OPTIONS_SUCCESS, payload: res.data });
        dispatch({ type: SET_TOPICS_AND_TYPES, payload: { types: res.data[0].questionTypes, topics: res.data[0].topics } });
        dispatch({
          type: SET_MENU_OPTIONS,
          payload: menuData,
        });
        dispatch(setCurrentMenuOptions(menuData));
      }
    })
    .catch(error => {
      dispatch({ type: GET_MENU_OPTIONS_FAILURE, payload: error.response });
    });
};

export const setMenuOptions = options => dispatch => {
  dispatch({ type: SET_MENU_OPTIONS, payload: options });
};
