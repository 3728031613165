import React, { useCallback, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import FKInput from '../FKInput/FKInput';
import * as Yup from 'yup';
import Popup from './Modal/Modal';

import styles from './DuplicateSentenceSearch.module.scss';

const validationSchema = Yup.object().shape({
  sentence: Yup.string().required(),
});

const DuplicateSentenceSearch = ({ type }) => {
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const onRequestClose = () => {
    setPopupIsOpen(false);
  };

  const handleSubmit = useCallback(() => {
    setPopupIsOpen(true);
  }, []);

  return (
    <Formik initialValues={{ sentence: '' }} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>
      {({ values }) => {
        return (
          <Form>
            {popupIsOpen && <Popup onRequestClose={onRequestClose} sentence={values.sentence} type={type} />}
            <h2 className={styles.formTitle}>Search</h2>
            <div className={styles.formContainer}>
              <Field name='sentence' component={FKInput} placeholder='Enter your sentence' />
              <button type='submit' className={styles.searchButton}>
                Search
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DuplicateSentenceSearch;
