import { combineReducers } from 'redux';
import auth from './Auth';
import question from './Question';
import asideMenu from './AsideMenu';
import listOfQuestions from './ListOfQuestions';
import statistics from './Statistics';
import dictionary from './Dictionary';
import tasks from './Tasks';

export const rootReducer = combineReducers({
  auth,
  question,
  asideMenu,
  listOfQuestions,
  statistics,
  dictionary,
  tasks,
});
