import React from 'react';
import Modal from 'react-modal';

import styles from './Modal.module.scss';
import { ReactComponent as CrossIcon } from '../../img/modalCross.svg';

const Popup = props => {
  const {
    isOpen,
    handleCloseModal = () => {},
    title = '',
    description = '',
    okLabel = 'Yes',
    okOnClick = () => {},
    cancelLabel = 'Cancel',
    cancelOnClick = () => {},
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={() => {}}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      style={{
        content: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 400,
          width: 850,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          padding: 25,
          paddingBottom: 50,
        },
        overlay: {
          backgroundColor: '#00000052',
        },
      }}
    >
      <div className={styles.modalHeader}>
        <span className={styles.title}>{title}</span>
        <CrossIcon onClick={handleCloseModal} className={styles.closeIcon} />
      </div>
      <p className={styles.description}>{description}</p>
      <div className={styles.buttonsContainer}>
        {cancelLabel && <button onClick={cancelOnClick}>{cancelLabel}</button>}
        {okLabel && (
          <button onClick={okOnClick} style={{ backgroundColor: '#313131', color: '#fff' }}>
            {okLabel}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default Popup;
