export const BASE_URL = 'https://admin.english.impltech.com/api';

export const pageTypes = {
  GRAMMAR: 'Grammar',
  LISTENING: 'Listening',
  VOCABULARY: 'Vocabulary',
};

export const RATING_MAX = 5000;
export const RATING_MIN = 0;

export const SUBSTITUTION_GRAMMAR = 'SUBSTITUTION_GRAMMAR';
export const CORRECT_MISTAKE_GRAMMAR = 'CORRECT_MISTAKE_GRAMMAR';
export const TRUE_OR_FALSE_GRAMMAR = 'TRUE_OR_FALSE_GRAMMAR';
export const WORD_ORDER_GRAMMAR = 'WORD_ORDER_GRAMMAR';
export const GROUP_GRAMMAR = 'GROUP_GRAMMAR';

export const LIST_OF_LEVELS = [
  { label: 'A1', value: 'A1' },
  { label: 'A2', value: 'A2' },
  { label: 'B1', value: 'B1' },
  { label: 'B2', value: 'B2' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
];
