import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

import styles from './Levels.module.scss';
import { setDictionaryLevels } from '../../../store/actions/Dictionary';
import { ReactComponent as ArrowRight } from '../../../img/arrow_right.svg';
import Spinner from '../../../components/Spinner/Spinner';

const tableColumnsTitle = [
  { name: 'Name', id: v4() },
  { name: 'Description', id: v4() },
  { name: 'Words', id: v4() },
  { name: '', id: v4() },
];

const Levels = () => {
  const dispatch = useDispatch();
  const { levels, loading } = useSelector(({ dictionary }) => dictionary);

  useEffect(() => {
    dispatch(setDictionaryLevels());
  }, [dispatch]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.pageContainer}>
      <h2 className={styles.pageTitle}>Levels</h2>
      <div className={styles.levelsTable}>
        <ul className={styles.levelsTableHeader}>
          {tableColumnsTitle.map(({ name, id }) => (
            <li key={id} className={styles.levelsTableHeaderTitle}>
              {name}
            </li>
          ))}
        </ul>
        <ul>
          {levels.map(({ level, name, description, words }, index) => (
            <li key={level}>
              <Link
                to={`/dictionary/levels/${level}/categories`}
                className={styles.levelsTableBodyItem}
                style={{ backgroundColor: index % 2 ? '#FFF' : '#F2F2F2' }}
              >
                <span className={styles.levelsTableText}>{name}</span>
                <span className={styles.levelsTableText}>{description}</span>
                <span className={styles.levelsTableText}>{words}</span>
                <ArrowRight className={styles.icon} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Levels;
