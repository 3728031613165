import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import styles from './Statistics.module.scss';
import Header from '../../components/Header/Header';
import { LIST_OF_LEVELS, RATING_MAX, RATING_MIN } from '../../constants/globalConstants';
import FKInput from '../../components/FKInput/FKInput';
import { setStatistics } from '../../store/actions';
import Spinner from '../../components/Spinner/Spinner';
import FKSelect from '../../components/FKSelect/FKSelect';

const listOfLevels = [{ label: 'ALL', value: null }, ...LIST_OF_LEVELS];

const Statistics = () => {
  const { statistics, loading, error, minRating, maxRating, level } = useSelector(({ statistics }) => statistics);
  const dispatch = useDispatch();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      ratingMin: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .required(),
      ratingMax: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .test('', function(value) {
          return this.parent.ratingMin <= value;
        })
        .required(),
    });
  }, []);

  const handleSubmit = useCallback(
    ({ ratingMin, ratingMax, level }, { setSubmitting }) => {
      setSubmitting(false);

      dispatch(setStatistics(ratingMin, ratingMax, level));
    },
    [dispatch],
  );

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    dispatch(setStatistics(minRating || RATING_MIN, maxRating || RATING_MAX, level));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <Header />
      <Formik
        initialValues={{
          ratingMin: minRating || RATING_MIN,
          ratingMax: maxRating || RATING_MAX,
          level: level,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {() => {
          return (
            <div className={styles.pageContainer}>
              {error ? (
                <div className={styles.errorContainer}>
                  <div className={styles.errorTitle}>Oops, something went wrong.</div>
                  <button type='button' className={styles.showButton} onClick={reloadPage}>
                    RELOAD
                  </button>
                </div>
              ) : loading ? (
                <Spinner />
              ) : (
                <>
                  <Form className={styles.filterContainer}>
                    <span>Level</span>
                    <div className={styles.inputsContainer}>
                      <Field name='level' component={FKSelect} placeholder='Select your level' width={200} options={listOfLevels} />
                    </div>
                    <span>Rating</span>
                    <div className={styles.inputsContainer}>
                      <div style={{ marginRight: 25 }}>
                        <Field name='ratingMin' type='number' component={FKInput} placeholder='From' width={200} />
                      </div>
                      <Field name='ratingMax' type='number' component={FKInput} placeholder='To' width={200} />
                    </div>
                    <button type='submit' className={styles.showButton}>
                      Show
                    </button>
                  </Form>
                  <section className={styles.dataContainer}>
                    {statistics.map((item, index) => (
                      <div key={index}>
                        <div className={styles.dataContainerHeader}>
                          <div className={styles.dataContainerTitle}>{item.type.toLowerCase()}</div>
                          <div className={styles.dataContainerCount}>{item.questionCount}</div>
                        </div>
                        <div className={styles.dataItemsContainer}>
                          {item.statistic.map((elem, index) => (
                            <div key={index} className={styles.dataItem}>
                              <div className={styles.dataItemTitle}>{elem.topicName}</div>
                              <div>{elem.questionCount}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </section>
                </>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default Statistics;
