import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = () => {
  const { currentOptions } = useSelector(({ asideMenu }) => asideMenu);

  return (
    <div className={styles.breadcrumbs}>
      <p>{`${currentOptions[0].name} > ${currentOptions[2].name} > ${currentOptions[1].name}`}</p>
    </div>
  );
};

export default Breadcrumbs;
