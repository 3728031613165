import React from 'react';
import Select from 'react-select';
import { getIn } from 'formik';

const FKSelect = ({ field, form, ...props }) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const borderColor = props?.borderColor || (error && touch ? '#E51A4B' : '#000');

  const styles = {
    control: () => ({
      display: 'flex',
      backgroundColor: '#F2F2F2',
      borderRadius: 0,
      outline: 'none',
      border: '1px solid #000',
      borderColor,
      boxShadow: 'none',
      boxSizing: 'border-box',
      width: props.width || '100%',
      height: props.height || 48,
      fontSize: 18,
      paddingLeft: 10,
      marginTop: 9,
      marginBottom: 9,
    }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 15px',
        backgroundColor: isSelected ? '#c4c4c4' : '#F2F2F2',
        color: '#000',
        height: 35,
        fontWeight: isSelected ? 'bold' : 'normal',
        cursor: isDisabled ? 'not-allowed' : 'default',

        '&:hover': {
          backgroundColor: '#c4c4c4',
        },
      };
    },
    menuList: styles => ({
      ...styles,
      backgroundColor: '#F2F2F2',
    }),
    menu: styles => ({
      ...styles,
      backgroundColor: '#F2F2F2',
      width: props.width,
      minWidth: 300,
    }),
    placeholder: styles => ({
      ...styles,
      color: '#555',
    }),
  };

  return <Select {...props} {...field} value={field.value} onChange={e => form.setFieldValue(field.name, e)} styles={styles} />;
};

export default FKSelect;
