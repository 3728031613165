import React from 'react';
import { getIn } from 'formik';
import Tooltip from 'react-simple-tooltip';

import styles from './FKInput.module.scss';
import { RATING_MAX, RATING_MIN } from '../../constants/globalConstants';

const FKInput = ({ field, form, ...props }) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const style = props?.style || {};

  return (
    <div
      className={styles.container}
      style={{
        width: props.width,
      }}
    >
      <div className={styles.formError}>
        {touch && error && (
          <Tooltip content={error}>
            <img src={require('../../img/cross.svg')} height={17} width={17} alt='cross' />
          </Tooltip>
        )}
      </div>
      <input
        {...field}
        {...props}
        type={props?.type || 'text'}
        min={props?.min || RATING_MIN}
        max={props?.max || RATING_MAX}
        className={styles.input}
        style={{
          borderColor: touch && error ? '#E51A4B' : '#000',
          height: props.height,
          backgroundColor: props.backgroundColor,
          ...style,
        }}
      />
    </div>
  );
};

export default FKInput;
