import React, { useState, useEffect, useRef } from 'react';
import styles from './SquareSelect.module.scss';
import cn from 'classnames';

const SquareSelect = ({ answerId, optionsList, handleChange, position }) => {
  const dropdownRef = useRef();
  const [isFieldOpen, setIsFieldOpen] = useState(false);

  useEffect(() => {
    let handler = e => {
      if (!dropdownRef.current.contains(e.target)) {
        setIsFieldOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <div ref={dropdownRef} className={styles.select}>
      <div
        className={styles.select__dropdown}
        onClick={() => {
          setIsFieldOpen(!isFieldOpen);
        }}
      >
        {Number.isInteger(position) ? position : 'None'}
      </div>
      <ul className={cn(`${styles.select__dropdownList}`, { [`${styles.select__dropdownList_active}`]: isFieldOpen })}>
        {optionsList &&
          optionsList.map(option => (
            <li
              className={styles.select__dropdownItem}
              key={option}
              onClick={() => {
                setIsFieldOpen(false);
                handleChange(answerId, option);
              }}
            >
              <span>{option}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SquareSelect;
