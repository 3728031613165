import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginRequest } from '../../store/actions';
import styles from './SignInForm.module.scss';

import Spinner from '../Spinner/Spinner';

const SignInForm = () => {
  const { loading } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const handleChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleCheckbox = e => {
    setUser({ ...user, rememberMe: !user.rememberMe });
  };

  const submit = e => {
    e.preventDefault();
    dispatch(loginRequest(user));
  };

  return (
    <form action='' onSubmit={e => submit(e)} className={styles.form}>
      <h1 className={styles.form__header}>
        <p className={styles.form__headerNormal}>Welcome back</p>
        <p className={styles.form__headerBold}>Login to your account</p>
      </h1>
      <label className={styles.form__email}>
        Login
        <input className={styles.form__input} type='email' name='email' value={user.email} onChange={e => handleChange(e)} />
      </label>
      <label className={styles.form__password}>
        Password
        <input className={styles.form__input} type='password' name='password' value={user.password} onChange={e => handleChange(e)} />
      </label>
      <label className={styles.form__rememberMe}>
        Remember Me
        <input className={styles.form__checkbox} type='checkbox' name='rememberMe' checked={user.rememberMe} onChange={e => handleCheckbox(e)} />
        <span className={styles.form__customCheckbox}></span>
      </label>
      <button className={styles.form__button}>{loading ? <Spinner styleButton={true} /> : 'Login'}</button>
    </form>
  );
};

export default SignInForm;
