import React from 'react';
import styles from './AddButton.module.scss';

const AddButton = ({ disabled, onClick }) => {
  return (
    <button
      type="button"
      className={styles.addButton}
      disabled={disabled}
      onClick={onClick}
    ></button>
  );
}

export default AddButton;
