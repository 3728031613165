import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './reducers';

const middlewares = [thunk, logger];

const index = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export default index;
