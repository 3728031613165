import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import styles from './NewWordOrder.module.scss';

import { editPopupOpen, popupOpen } from '../../../../store/actions';
import TextInput from '../../../../components/TextInput/TextInput';
import AddButton from '../../../../components/AddButton/AddButton';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import EditGrammarPopupButtons from '../../../../components/EditGrammarPopupButtons/EditGrammarPopupButtons';
import DuplicateSentenceSearch from '../../../../components/DuplicateSentenceSearch/DuplicateSentenceSearch';
import { LIST_OF_LEVELS } from '../../../../constants/globalConstants';
import FKInput from '../../../../components/FKInput/FKInput';
import FKSelect from '../../../../components/FKSelect/FKSelect';
import {getFormattedTime} from '../../../../constants/globalFunctions';

const TIME_ID = uuidv4();
const LEVEL_ID = uuidv4();
const DEFAULT_RATING_ID = uuidv4();
const defaultParts = {
  words: [
    {
      id: uuidv4(),
      value: '',
      type: 'words',
    },
    {
      id: uuidv4(),
      value: '',
      type: 'words',
    },
  ],
  rating: 0,
};

const NewWordOrder = ({ question, handleClosePopup = () => {} }) => {
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);
  const INPUT_WIDTH = 200 + Boolean(!isEditPage) * 50;
  const { pathname } = useLocation();
  const hideDuplicateSentenceSearch = pathname !== '/questions' && isEditPage;
  const { creationSuccess, levels } = useSelector(({ question }) => question);
  const { options } = useSelector(({ asideMenu }) => asideMenu);
  const [parts, setParts] = useState(question || defaultParts);
  const [topicName, setTopicName] = useState(question?.topicName);
  const [level, setLevel] = useState(question?.level || null);
  const [defaultRating, setDefaultRating] = useState(question?.defaultRating || 0);
  const [time, setTime] = useState(question?.time || 0);
  const [errorsData, setErrorsData] = useState([]);
  const maxSentencesLimit = 12;
  const minSentencesLimit = 2;

  const levelRatingRange = useMemo(() => {
    if (!level) {
      return { min: 0, max: 0 };
    }

    return levels[level.value];
  }, [level, levels]);

  const validation = () => {
    const errors = [];

    parts.words.forEach(word => {
      const value = word.value;

      if (!value || value.length > 20) {
        errors.push(word.id);
      }
    });

    if (time < 3 || time > 180) {
      errors.push(TIME_ID);
    }

    if (!level) {
      errors.push(LEVEL_ID);
    }

    if (defaultRating < levelRatingRange.min || defaultRating > levelRatingRange.max) {
      errors.push(DEFAULT_RATING_ID);
    }

    if (errors.length > 0) {
      setErrorsData([...errors]);

      return false;
    }

    return true;
  };

  const resetError = id => {
    setErrorsData(prevState => prevState.filter(errorId => errorId !== id));
  };

  const listOfTopics = useMemo(() => {
    if (question) {
      const { topics } = options.find(({ topicType }) => topicType === question.topicType);

      return topics.map(({ name }) => ({ label: name, value: name }));
    }

    return [];
  }, [options, question]);

  useEffect(() => {
    if (creationSuccess) {
      setParts(defaultParts);
      setDefaultRating(0);
      setLevel(null);
      setTime(0);
    }
  }, [creationSuccess]);

  const newPartConstructor = type => ({
    id: uuidv4(),
    value: '',
    type,
  });

  const handleChangeWord = (id, value, name, partType) => {
    setParts(prevState => ({
      ...prevState,
      [partType]: prevState[partType].map(part => {
        if (part.id === id) {
          return {
            ...part,
            [name]: value.trim(),
          };
        }

        return part;
      }),
    }));
  };

  const handleAddNewWord = () => {
    const newWord = newPartConstructor('words');

    setParts(prevState => ({
      ...prevState,
      words: [...prevState.words, newWord],
    }));
  };

  const handleDeleteWord = id => {
    setParts(prevState => ({
      ...prevState,
      words: prevState.words.filter(word => word.id !== id),
    }));
  };

  const openPopup = () => {
    const rating = parts.rating;
    const words = parts.words.map(word => word.value);
    const answers = parts.words.map((word, index) => ({
      name: word.value,
      ordinalRank: index,
    }));

    const requestData = {
      sentences: words,
      answers,
      rating,
      recommendedTime: getFormattedTime(time),
      level: level.value,
      defaultRating,
      ...(isEditPage && {
        topicName: topicName.value,
        topicType: question.topicType,
      }),
    };

    if (isEditPage) {
      const { id } = question;

      dispatch(editPopupOpen(id, requestData));
    } else {
      dispatch(popupOpen(requestData));
    }
  };

  const handleSubmit = () => {
    const isFormValid = validation();

    if (isFormValid) {
      openPopup();
    }
  };

  return (
    <main className={styles.main}>
      {!hideDuplicateSentenceSearch && <DuplicateSentenceSearch type='WORD_ORDER_GRAMMAR' />}
      <section className={styles.words}>
        <div className={styles.words__header}>
          <h2 className={styles.words__title}>Question</h2>
        </div>
        <div className={styles.words__wrapper}>
          <ul className={styles.words__list}>
            {parts.words.map(word => {
              const isError = errorsData.includes(word.id);

              return (
                <li key={word.id} className={styles.words__item}>
                  <TextInput isError={isError} resetError={resetError} part={{ ...word, type: 'words' }} setValue={handleChangeWord} resetValue={creationSuccess} />
                  <DeleteButton disabled={parts.words.length <= minSentencesLimit} onClick={() => handleDeleteWord(word.id)} />
                </li>
              );
            })}
          </ul>
          <AddButton disabled={parts.words.length >= maxSentencesLimit} onClick={handleAddNewWord} />
        </div>
      </section>
      <section className={styles.additionalSettings}>
        {isEditPage && (
          <div style={{ marginRight: 50 }}>
            <h2>Topic</h2>
            <FKSelect
              form={{ errors: {}, touched: true, setFieldValue: (_, value) => setTopicName(value) }}
              field={{ name: 'topicName', value: topicName }}
              options={listOfTopics}
              placeholder='Select your topic'
              width={INPUT_WIDTH}
            />
          </div>
        )}
        <div>
          <h2>Time (s)</h2>
          <FKInput
            form={{ errors: {}, touched: true }}
            field={{ name: 'time' }}
            type='number'
            placeholder='Enter your time'
            style={{ borderColor: errorsData.includes(TIME_ID) ? 'red' : 'black' }}
            value={time}
            width={INPUT_WIDTH}
            onChange={({ target: { value } }) => setTime(value)}
            onFocus={() => resetError(TIME_ID)}
          />
        </div>
        <div style={{ marginLeft: 50 }}>
          <h2>Level</h2>
          <FKSelect
            form={{ errors: {}, touched: true, setFieldValue: (_, value) => setLevel(value) }}
            field={{ name: 'level', value: level }}
            borderColor={errorsData.includes(LEVEL_ID) ? 'red' : 'black'}
            options={LIST_OF_LEVELS}
            placeholder='Select your level'
            width={INPUT_WIDTH}
            onFocus={() => resetError(LEVEL_ID)}
          />
        </div>
        <div style={{ marginLeft: 50, opacity: level ? 1 : 0.5 }}>
          <h2>Default rating ({levelRatingRange.min} - {levelRatingRange.max})</h2>
          <FKInput
            form={{ errors: {}, touched: true }}
            field={{ name: 'default_rating' }}
            type='number'
            min={levelRatingRange.min}
            max={levelRatingRange.max}
            placeholder='Enter your default rating'
            disabled={!level}
            style={{ borderColor: errorsData.includes(DEFAULT_RATING_ID) ? 'red' : 'black' }}
            value={defaultRating}
            width={INPUT_WIDTH}
            onChange={({ target: { value } }) => setDefaultRating(value)}
            onFocus={() => resetError(DEFAULT_RATING_ID)}
          />
        </div>
      </section>
      <section className={styles.preview}>
        <div className={styles.preview__header}>
          <h2 className={styles.preview__title}>Preview</h2>
        </div>
        <ul className={styles.preview__list}>
          {parts.words.map(part => (
            <li key={part.id} className={styles.preview__item}>
              <span>{part.value}</span>
            </li>
          ))}
        </ul>
      </section>
      {isEditPage ? (
        <EditGrammarPopupButtons handleClose={handleClosePopup} handleSubmit={handleSubmit} />
      ) : (
        <SaveButton saveSentence={handleSubmit} />
      )}
    </main>
  );
};

export default NewWordOrder;
