import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './Aside.module.scss';

import { setCurrentMenuOptions, setMenuOptions, setTopicsAndTypes } from '../../store/actions/';

import Navigation from '../Navigation/Navigation';

const Aside = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedOptions, types, topics, options } = useSelector(({ asideMenu }) => asideMenu);

  const [isFirstNavigationVisible, setIsFirstNavigationVisible] = useState(false);
  const [isSecondNavigationVisible, setIsSecondNavigationVisible] = useState(false);
  const [isThirdNavigationVisible, setIsThirdNavigationVisible] = useState(false);

  const handleMenuOptions = (option, index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = option;
    dispatch(setMenuOptions(updatedOptions));
  };

  const handleSelectNavigation = (value, fieldName, index) => {
    switch (fieldName) {
      case 'navigation 1':
        setIsFirstNavigationVisible(false);
        const firstOption = { name: options[index].topicTypeName, topicType: options[index].topicType };
        dispatch(setTopicsAndTypes(firstOption, options[index].topics, options[index].questionTypes));
        break;
      case 'navigation 2':
        setIsSecondNavigationVisible(false);
        handleMenuOptions(value, 1);
        break;
      case 'navigation 3':
        setIsThirdNavigationVisible(false);
        handleMenuOptions(value, 2);
        break;
      default:
    }
  };

  const generateLinkPath = index => {
    return `${selectedOptions[index].name.replace(/\W/g, '').toLowerCase()}`;
  };

  const saveNavigationPath = () => {
    dispatch(setMenuOptions([...selectedOptions]));
    dispatch(setCurrentMenuOptions([...selectedOptions]));
    history.push(`/${generateLinkPath(0)}/${generateLinkPath(2)}`);
  };

  const mainOptions = options.map(option => {
    return { id: option.topicTypeId, topicType: option.topicType, name: option.topicTypeName };
  });

  return (
    <aside className={styles.aside}>
      <div>
        <Navigation
          optionsList={mainOptions}
          placeholder={selectedOptions[0].name}
          handleSelectNavigation={handleSelectNavigation}
          isNavigationVisible={isFirstNavigationVisible}
          setIsNavigationVisible={setIsFirstNavigationVisible}
          fieldName='navigation 1'
        />
        <Navigation
          optionsList={types}
          placeholder={selectedOptions[2].name}
          handleSelectNavigation={handleSelectNavigation}
          isNavigationVisible={isThirdNavigationVisible}
          setIsNavigationVisible={setIsThirdNavigationVisible}
          fieldName='navigation 3'
        />
        <Navigation
          optionsList={topics}
          placeholder={selectedOptions[1].name}
          handleSelectNavigation={handleSelectNavigation}
          isNavigationVisible={isSecondNavigationVisible}
          setIsNavigationVisible={setIsSecondNavigationVisible}
          fieldName='navigation 2'
        />

        <button className={styles.aside__showButton} onClick={saveNavigationPath}>
          SHOW
        </button>
      </div>
    </aside>
  );
};

export default Aside;
