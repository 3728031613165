import React from 'react';
import styles from './DeleteButton.module.scss';

const DeleteButton = ({ disabled, onClick }) => {
  return (
    <button
      type="button"
      className={styles.deleteButton}
      disabled={disabled}
      onClick={onClick}
    ></button>
  );
}

export default DeleteButton;
