import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getListOfQuestions } from '../../store/actions';

import Header from '../../components/Header/Header';
import ListOfQuestionsPopup from '../../components/ListOfQuestionsPopup/ListOfQuestionsPopup';
import Spinner from '../../components/Spinner/Spinner';
import ListOfQuestionsContent from '../../components/ListOfQuestionsContent/ListOfQuestionsContent';

const ListOfQuestions = () => {
  const dispatch = useDispatch();
  const { creationSuccess } = useSelector(({ question }) => question);
  const { loading } = useSelector(({ listOfQuestions }) => listOfQuestions);

  const url = new URL(window.location.href);
  const page = url.searchParams.get('page');

  useEffect(() => {
    dispatch(getListOfQuestions());
  }, [dispatch]);

  useEffect(() => {
    if (creationSuccess) {
      dispatch(getListOfQuestions(page - 1 || 0));
    }
  }, [dispatch, page, creationSuccess]);

  return (
    <>
      <Header />
      {loading ? <Spinner /> : <ListOfQuestionsContent />}
      <ListOfQuestionsPopup />
    </>
  );
};

export default ListOfQuestions;
