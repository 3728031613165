import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from './ListOfQuestionsContent.module.scss';
import Question from '../Question/Question';
import { getListOfQuestions, closeEditModal } from '../../store/actions';
import EditQuestionPopup from '../EditQuestionPopup/EditQuestionPopup';

const headers = [
  { id: 0, name: 'ID' },
  { id: 1, name: 'Date' },
  { id: 2, name: 'Category' },
  { id: 3, name: 'Type' },
  { id: 4, name: 'Rating' },
  { id: 5, name: 'Delete' },
];

const ListOfQuestionsContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, pagination, modalIsOpen, currentQuestion } = useSelector(({ listOfQuestions }) => listOfQuestions);

  const handlePageClick = ({ selected }) => {
    history.push(`/questions?page=${selected + 1}`);
    dispatch(getListOfQuestions(selected));
  };

  const handleCloseModal = useCallback(() => {
    dispatch(closeEditModal());
  }, [dispatch]);

  return (
    <div className={styles.questions}>
      <EditQuestionPopup
          question={currentQuestion}
          modalIsOpen={modalIsOpen}
          handleCloseModal={handleCloseModal}
      />
      <ul className={styles.questions__headers}>
        {headers.map(title => (
          <li key={title.id} className={styles.questions__title}>
            <span>{title.name}</span>
          </li>
        ))}
      </ul>
      <ul className={styles.questions__list}>
        {data.content &&
          data.content.map(question => (
            <li key={question.id} className={styles.questions__item}>
              <Question question={question} />
            </li>
          ))}
      </ul>

      <div className={styles.pagination__wrap}>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          pageCount={pagination.totalPages}
          forcePage={pagination.currentPage}
          onPageChange={page => handlePageClick(page)}
          containerClassName={styles.pagination__content}
          pageClassName={styles.pagination__button}
          pageLinkClassName={styles.pagination__link}
          previousLinkClassName={styles.pagination__button}
          nextLinkClassName={styles.pagination__button}
          disabledClassName={styles.pagination__button_disabled}
          activeClassName={`${styles.pagination__button} ${styles.pagination__button_active}`}
          hrefBuilder={index => `/question?page=${index}`}
        />
      </div>
    </div>
  );
};

export default ListOfQuestionsContent;
