import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import styles from './ListeningLetterOrder.module.scss';
import { LIST_OF_LEVELS, RATING_MAX, RATING_MIN } from '../../../../constants/globalConstants';
import { editPopupOpen, popupOpen } from '../../../../store/actions';
import FKInput from '../../../../components/FKInput/FKInput';
import EditPopupButtons from '../../../../components/EditPopupButtons/EditPopupButtons';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import { getFormattedTime } from '../../../../constants/globalFunctions';
import FKSelect from '../../../../components/FKSelect/FKSelect';

const ListeningLetterOrder = ({ question, handleClosePopup = () => {} }) => {
  const { levels } = useSelector(({ question }) => question);
  const { options } = useSelector(({ asideMenu }) => asideMenu);
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);
  const INPUT_WIDTH = 200 + Boolean(!isEditPage) * 50;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      sentences: Yup.array().of(
        Yup.object().shape({
          sentence: Yup.string()
            .max(20)
            .required(),
        }),
      ),
      rating: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .required(),
      defaultRating: Yup.number()
        .test('', function(value) {
          const level = this.parent.level?.value;

          if (level) {
            return value >= levels[level].min && value <= levels[level].max;
          }

          return false;
        })
        .required(),
      time: Yup.number()
        .min(3)
        .max(180)
        .required(),
      level: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required(),
    });
  }, [levels]);

  const initialState = {
    sentences: [{ sentence: '', id: uuid() }],
    rating: 0,
    defaultRating: 0,
    time: 0,
    level: null,
  };

  const listOfTopics = useMemo(() => {
    if (question) {
      const { topics } = options.find(({ topicType }) => topicType === question.topicType);

      return topics.map(({ name }) => ({ label: name, value: name }));
    }

    return [];
  }, [options, question]);

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const requestSentences = values.sentences.map(({ sentence }) => sentence.trim());
      const answerList = requestSentences[0].split('').map((letter, index) => ({ ordinalRank: index, name: letter }));

      const requestData = {
        sentences: requestSentences,
        answers: answerList,
        rating: values.rating,
        recommendedTime: getFormattedTime(values.time),
        level: values.level.value,
        defaultRating: values.defaultRating,
        ...(isEditPage && {
          topicName: values.topicName.value,
          topicType: question.topicType,
        }),
      };

      if (isEditPage) {
        const { id } = question;

        dispatch(editPopupOpen(id, requestData));
      } else {
        dispatch(popupOpen(requestData, resetForm));
      }

      setSubmitting(false);
    },
    [dispatch, isEditPage, question],
  );

  return (
    <Formik initialValues={question || initialState} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>
      {({ values }) => {
        const levelRatingRange = values.level ? levels[values.level.value] : { min: 0, max: 0 };

        return (
          <Form>
            <section>
              <h3>Word</h3>
              <Field name='sentences[0].sentence' component={FKInput} placeholder='Enter your word' height={44} width='50%' />
            </section>
            <section className={styles.additionalSettings}>
              {isEditPage && (
                <div style={{ marginRight: 50 }}>
                  <h2>Topic</h2>
                  <Field
                    name='topicName'
                    component={FKSelect}
                    placeholder='Select your topic'
                    height={44}
                    width={INPUT_WIDTH}
                    options={listOfTopics}
                  />
                </div>
              )}
              <div>
                <h2>Time (s)</h2>
                <Field name='time' component={FKInput} placeholder='Enter your time' height={44} width={INPUT_WIDTH} type='number' />
              </div>
              <div style={{ marginLeft: 50 }}>
                <h2>Level</h2>
                <Field name='level' component={FKSelect} placeholder='Select your level' height={44} width={INPUT_WIDTH} options={LIST_OF_LEVELS} />
              </div>
              <div style={{ marginLeft: 50, opacity: values.level ? 1 : 0.5 }}>
                <h2>
                  DefaultRating ({levelRatingRange.min} - {levelRatingRange.max})
                </h2>
                <Field
                  name='defaultRating'
                  component={FKInput}
                  min={levelRatingRange.min}
                  max={levelRatingRange.max}
                  placeholder='Enter your default rating'
                  disabled={!values.level}
                  height={44}
                  width={INPUT_WIDTH}
                  type='number'
                />
              </div>
            </section>
            {isEditPage ? <EditPopupButtons handleClose={handleClosePopup} /> : <SaveButton type='submit' />}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ListeningLetterOrder;
