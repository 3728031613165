import {
  EDIT_DICTIONARY_WORD,
  RESET_DICTIONARY_ERROR,
  SET_ACTIVATE_DICTIONARY_LOADER,
  SET_DICTIONARY_CATEGORIES,
  SET_DICTIONARY_ERROR,
  SET_DICTIONARY_LEVELS,
  SET_DICTIONARY_WORDS,
} from '../constants/';

const initialState = {
  levels: [],
  categories: [],
  countOfPages: 0,
  totalElements: 0,
  words: [],
  loading: true,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DICTIONARY_LEVELS:
      return {
        ...state,
        levels: payload,
        loading: false,
        error: null,
      };

    case SET_DICTIONARY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case RESET_DICTIONARY_ERROR:
      return {
        ...state,
        error: null,
      };

    case SET_DICTIONARY_CATEGORIES:
      return {
        ...state,
        categories: payload,
        loading: false,
        error: null,
      };

    case SET_DICTIONARY_WORDS:
      return {
        ...state,
        words: payload.content,
        countOfPages: payload.totalPages,
        totalElements: payload.totalElements,
        loading: false,
        error: null,
      };

    case EDIT_DICTIONARY_WORD:
      return {
        ...state,
        words: state.words.map(item => {
          if (item.id === payload.id) {
            return payload;
          }

          return item;
        }),
        loading: false,
        error: null,
      };

    case SET_ACTIVATE_DICTIONARY_LOADER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    default:
      return state;
  }
};
