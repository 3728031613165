import {
  GET_MENU_OPTIONS_REQUEST,
  GET_MENU_OPTIONS_SUCCESS,
  GET_MENU_OPTIONS_FAILURE,
  SET_MENU_OPTIONS,
  SET_TOPICS_AND_TYPES,
  SET_CURRENT_MENU_OPTIONS,
  SET_MENU_CURRENT_LEVEL,
} from '../constants/';

const INIT_STATE = {
  topics: [{ name: 'Prepositions' }],
  types: [{ name: 'Substitution' }],
  currentLevel: 'A1',
  options: [],
  loading: false,
  selectedOptions: [{ name: '' }, { name: '', id: null }, { name: '', type: '' }],
  currentOptions: [{ name: '' }, { name: '', id: null }, { name: '', type: '' }],
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MENU_OPTIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_MENU_OPTIONS_SUCCESS:
      return { ...state, options: action.payload, loading: false };
    case GET_MENU_OPTIONS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case SET_MENU_CURRENT_LEVEL:
      return { ...state, currentLevel: action.payload };

    case SET_MENU_OPTIONS:
      return { ...state, selectedOptions: action.payload };
    case SET_CURRENT_MENU_OPTIONS:
      return { ...state, currentOptions: action.payload };
    case SET_TOPICS_AND_TYPES:
      return { ...state, topics: action.payload.topics, types: action.payload.types };

    default:
      return state;
  }
};
