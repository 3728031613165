import {
  QUESTION_POPUP_OPEN,
  QUESTION_POPUP_CLOSE,
  ADD_QUESTION_REQUEST,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAILURE,
  EDIT_QUESTION_POPUP_OPEN,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE,
  DELETE_QUESTION_POPUP_OPEN,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  SET_DUPLICATE_SENTENCES,
  ADD_QUESTION_LEVELS,
} from '../constants/';

const INIT_STATE = {
  data: {},
  duplicateSentences: {
    content: [],
    empty: false,
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      offset: 0,
      pageSize: 0,
      pageNumber: 0,
    },
    size: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    totalElements: 0,
    totalPages: 0,
  },
  selectedId: null,
  isPopupOpen: false,
  isEditingPopupOpen: false,
  loading: false,
  error: null,
  creationSuccess: false,
  resetForm: () => {},
  levels: {
    A1: { min: 1, max: 119 },
    A2: { min: 120, max: 299 },
    B1: { min: 300, max: 599 },
    B2: { min: 600, max: 999 },
    C1: { min: 1000, max: 1499 },
    C2: { min: 1500, max: 2999 },
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case QUESTION_POPUP_OPEN:
      return { ...state, isPopupOpen: true, data: action.payload, resetForm: action.resetForm };
    case QUESTION_POPUP_CLOSE:
      return { ...state, isPopupOpen: false, isEditingPopupOpen: false, data: {}, selectedId: null, creationSuccess: false, error: null };

    case EDIT_QUESTION_POPUP_OPEN:
      return { ...state, isEditingPopupOpen: true, selectedId: action.id, data: action.payload };

    case DELETE_QUESTION_POPUP_OPEN:
      return { ...state, isEditingPopupOpen: true, selectedId: action.id };

    case SET_DUPLICATE_SENTENCES:
      return { ...state, loading: false, duplicateSentences: action.payload };

    case ADD_QUESTION_REQUEST:
      return { ...state, loading: true, error: null };
    case ADD_QUESTION_SUCCESS:
      return { ...state, loading: false, creationSuccess: true };
    case ADD_QUESTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case EDIT_QUESTION_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_QUESTION_SUCCESS:
      return { ...state, loading: false, creationSuccess: true };
    case EDIT_QUESTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case DELETE_QUESTION_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_QUESTION_SUCCESS:
      return { ...state, loading: false, creationSuccess: true };
    case DELETE_QUESTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ADD_QUESTION_LEVELS:
      return {
        ...state,
        levels: action.payload,
      };

    default:
      return state;
  }
};
