export const getFormattedTime = (time = 0) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  const timeFormatter = time => {
    return time.toString().length < 2 ? `0${time}` : `${time}`;
  };

  if (minutes > 59) {
    return `00:59:59`;
  }

  return `00:${timeFormatter(minutes)}:${timeFormatter(seconds)}`;
};

export const getSecondsByTime = (time = '00:00:00') => {
  const splitTime = time.split(':');

  return splitTime.reduce((acc, time, index, array) => {
    return acc + Number(time) * 60 ** (array.length - index - 1);
  }, 0);
};
