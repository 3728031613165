import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './SignIn.module.scss';
import logo from '../../img/logo.svg';

import SignInForm from '../../components/SignInForm/SignInForm';

const SignIn = () => {
  const { token } = useSelector(({ auth }) => auth);
  const history = useHistory();
  useEffect(() => {
    if (token) {
      history.push('/grammar/substitution');
    }
  }, [token, history]);

  return (
    <div className={styles.login}>
      <div className={styles.login__logo}>
        <img className={styles.login__img} src={logo} alt='logo' />
      </div>
      <div className={styles.login__form}>
        <SignInForm />
      </div>
    </div>
  );
};

export default SignIn;
