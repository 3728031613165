import React from 'react';
import './Spinner.scss';

import cn from 'classnames';

const Spinner = ({ styleButton }) => {
  return (
    <div className={cn("lds-spinner", {
      "lds-spinner-button": styleButton
    })}
    ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  );
}

export default Spinner;