import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { closeEditModal, deletePopupOpen, getDuplicateSentencesByType, openEditModal } from '../../../store/actions';
import ReactPaginate from 'react-paginate';
import { v4 as uuid } from 'uuid';

import styles from './Modal.module.scss';
import { ReactComponent as CrossIcon } from '../../../img/modalCross.svg';
import { ReactComponent as RemoveIcon } from '../../../img/cross.svg';
import { ReactComponent as NextIcon } from '../../../img/next.svg';
import Spinner from '../../Spinner/Spinner';
import EditQuestionPopup from '../../EditQuestionPopup/EditQuestionPopup';

const PAGE_SIZE = 9;
export const capitalize = str => {
  if (typeof str !== 'string') {
    return null;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

const Popup = ({ onRequestClose, type, sentence }) => {
  const { error, loading, duplicateSentences, creationSuccess } = useSelector(({ question }) => question);
  const { currentOptions } = useSelector(({ asideMenu }) => asideMenu);
  const { modalIsOpen, currentQuestion } = useSelector(({ listOfQuestions }) => listOfQuestions);
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const [page, setPage] = useState(0);

  const handleRemoveSentence = (e, id) => {
    e.stopPropagation();

    dispatch(deletePopupOpen(id, {}));
  };

  const handleCloseEditModal = () => {
    dispatch(closeEditModal());
  };

  const handleOpenEditModal = question => {
    const questionTypes = {
      topicType: currentOptions[0].topicType,
      topicName: '',
      questionType: currentOptions[2].type,
    };
    const isAnswerInBeginning = question.sentence[0] === '_';

    const formattedQuestion = {
      ...question,
      answers: question.answers.map(item => ({
        id: uuid(),
        position: Number.isInteger(item.ordinalRank) && !isAnswerInBeginning ? item.ordinalRank + 1 : item.ordinalRank,
        value: item.name,
        type: 'answers',
      })),
      ...questionTypes,
    };

    dispatch(openEditModal(formattedQuestion));
  };

  const createCorrectSentence = useCallback(
    (answers, sentence) => {
      switch (true) {
        case type === 'SUBSTITUTION_GRAMMAR' || type === 'CORRECT_MISTAKE_GRAMMAR' || type === 'DEFINITION_VOCABULARY': {
          let correctSentence = sentence;
          const correctAnswers = answers.filter(({ ordinalRank }) => Number.isInteger(ordinalRank));

          correctAnswers.forEach(({ name }, index) => {
            const inTheBeginning = sentence.split(' ')[0] === '_';
            const formattedName = inTheBeginning && index === 0 ? capitalize(name) : name;

            correctSentence = correctSentence.replace('_', formattedName);
          });

          return correctSentence;
        }

        case type === 'WORD_ORDER_GRAMMAR': {
          return sentence.replaceAll(' ', '').replaceAll('/', ' ');
        }

        case type === 'GROUP_GRAMMAR': {
          let formattedSentence = '';
          const listOfColumn = sentence.split('/');
          const columnWithSolutions = listOfColumn.map((column, columnIndex) => {
            const solution = answers.filter(({ ordinalRank }) => ordinalRank === columnIndex + 1).map(({ name }) => name);

            return {
              column,
              solution,
            };
          });

          columnWithSolutions.forEach(item => {
            formattedSentence = `${formattedSentence} ${item.column} ---> (${item.solution.join(', ')});`;
          });

          return formattedSentence;
        }

        case type === 'PICTURE_VOCABULARY': {
          const joinAnswers = answers.map(({ name }) => name).join(', ');

          return `Answers: (${joinAnswers})`;
        }

        case type === 'GAP_FILL_VOCABULARY': {
          const inTheBeginning = sentence[0] === '_';
          const solution = answers.map(({ name }, index) => (inTheBeginning && !index ? name.toUpperCase() : name)).join('');

          return sentence.replace('_', solution);
        }

        default:
          return null;
      }
    },
    [type],
  );

  useEffect(() => {
    if (!creationSuccess) {
      const formattedSentence = sentence.split(' ');

      dispatch(getDuplicateSentencesByType(type, formattedSentence, page, PAGE_SIZE));
    }
  }, [type, sentence, page, dispatch, creationSuccess]);

  return (
    <Modal
      isOpen
      onAfterOpen={() => {}}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      style={{
        content: {
          display: 'flex',
          flexDirection: 'column',
          height: '80%',
          width: '80%',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          padding: 25,
        },
        overlay: {
          backgroundColor: '#00000052',
          zIndex: Number(pathname === '/questions'),
        },
      }}
    >
      {currentQuestion && <EditQuestionPopup question={currentQuestion} modalIsOpen={modalIsOpen} handleCloseModal={handleCloseEditModal} />}
      <div className={styles.modalHeader}>
        <span className={styles.title}>Search results page</span>
        <CrossIcon onClick={onRequestClose} className={styles.closeIcon} />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div className={styles.headerText} style={{ width: 80 }}>
              ID
            </div>
            <div className={styles.headerText} style={{ width: 150 }}>
              Date
            </div>
            <div className={styles.headerText} style={{ width: '100%' }}>
              Sentence
            </div>
            <div className={styles.headerText} style={{ width: 100 }}>
              Rating
            </div>
            <div className={styles.headerText} style={{ width: 70 }}>
              Delete
            </div>
          </div>
          {error ? (
            <div className={styles.message} style={{ color: 'red' }}>
              {error.message}
            </div>
          ) : (
            <>
              {duplicateSentences.content.length ? (
                <div>
                  {duplicateSentences.content.map((item, index) => {
                    const backgroundColor = index % 2 ? '#FFF' : '#F2F2F2';
                    const sentence = createCorrectSentence(item.answers, item.sentence);

                    return (
                      <div className={styles.tableItem} style={{ backgroundColor }} onClick={() => handleOpenEditModal(item)}>
                        <div style={{ width: 80 }}>{item.id}</div>
                        <div style={{ width: 150 }}>{item.createdAt}</div>
                        <div style={{ width: '100%', paddingRight: 25 }}>{sentence}</div>
                        <div style={{ width: 100 }}>{item.rating}</div>
                        <div style={{ width: 70 }}>
                          <RemoveIcon style={{ cursor: 'pointer' }} onClick={e => handleRemoveSentence(e, item.id)} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={styles.message}>Empty</div>
              )}
            </>
          )}
          {duplicateSentences.totalElements > PAGE_SIZE && (
            <ReactPaginate
              previousLabel={<NextIcon style={{ transform: 'rotate(180deg)' }} />}
              nextLabel={<NextIcon />}
              pageCount={duplicateSentences.totalPages}
              forcePage={page}
              onPageChange={({ selected }) => setPage(selected)}
              containerClassName={styles.paginationContent}
              pageClassName={styles.paginationButton}
              previousLinkClassName={styles.paginationNavigationButton}
              nextLinkClassName={styles.paginationNavigationButton}
              disabledClassName={styles.paginationDisabledButton}
              activeClassName={styles.paginationActiveButton}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default Popup;
