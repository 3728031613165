import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AddQuestionContent from '../../components/AddQuestionContent/AddQuestionContent';
import { useSelector } from 'react-redux';

import Header from '../../components/Header/Header';
import NewPopup from '../../components/NewPopup/NewPopup';
import Spinner from '../../components/Spinner/Spinner';
import ListOfQuestionsPopup from '../../components/ListOfQuestionsPopup/ListOfQuestionsPopup';

const AddQuestion = ({ match }) => {
  const history = useHistory();
  const { loading, selectedOptions } = useSelector(({ asideMenu }) => asideMenu);

  useEffect(() => {
    !selectedOptions[1].id && history.push('/grammar/substitution');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='container'>
      <Header />
      {loading ? <Spinner /> : <AddQuestionContent match={match} />}
      <NewPopup />
      <ListOfQuestionsPopup />
    </div>
  );
};

export default AddQuestion;
