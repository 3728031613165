import {
  SIGNIN_USER_REQUEST,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
} from '../constants/';
import axios from 'axios';

export const loginRequest = user => dispatch => {
  dispatch({ type: SIGNIN_USER_REQUEST });
  axios
    .post('login/email', user, { headers: { Authorization: '' } })
    .then(res => {
      // console.log(res);
      if (res.status === 200 && res.data.accessToken && res.data.refreshToken) {
        dispatch({ type: SIGNIN_USER_SUCCESS });
        localStorage.setItem('englishAdminToken', `Bearer ${res.data.accessToken}`);
        localStorage.setItem('englishAdminRefreshToken', res.data.refreshToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
      }
    })
    .catch(error => {
      dispatch({ type: SIGNIN_USER_FAILURE, payload: error.response });
    });
};

export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER_SUCCESS });
  localStorage.removeItem('englishAdminToken');
  localStorage.removeItem('englishAdminRefreshToken');
};

export const logoutUserRequest = () => dispatch => {
  const refreshToken = localStorage.getItem('englishAdminRefreshToken');
  dispatch({ type: LOGOUT_USER_REQUEST });
  axios
    .post('logout', { refreshToken })
    .then(res => {
      // console.log(res);
      if (res.status === 200) {
        dispatch(logoutUser());
      }
    })
    .catch(error => {
      dispatch({ type: LOGOUT_USER_FAILURE, payload: error.response });
    });
};

export const refreshTokenRequest = async refreshToken => {
  let newToken = null;
  await axios.post('/token/refresh', { refreshToken }, { headers: { Authorization: '' } }).then(res => {
    if (res.status === 200 && res.data.accessToken && res.data.refreshToken) {
      localStorage.setItem('englishAdminToken', `Bearer ${res.data.accessToken}`);
      localStorage.setItem('englishAdminRefreshToken', res.data.refreshToken);
      newToken = res.data.accessToken;
    }
  });

  return newToken;
};
