import { SET_ACTIVATE_LOADER, SET_STATISTICS, SET_STATISTICS_ERROR, SET_STATISTICS_LEVEL, SET_STATISTICS_RATING } from '../constants/';
import axios from 'axios';

export const setStatistics = (ratingFrom, ratingTo, level) => dispatch => {
  dispatch({ type: SET_ACTIVATE_LOADER });

  axios
    .get('/topic/statistic', { params: { ...(level.value && { level: level.value }), ratingFrom, ratingTo } })
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: SET_STATISTICS, payload: res.data });
        dispatch({ type: SET_STATISTICS_RATING, payload: { minRating: ratingFrom, maxRating: ratingTo } });
        dispatch({ type: SET_STATISTICS_LEVEL, payload: level });
      }
    })
    .catch(error => {
      dispatch({ type: SET_STATISTICS_ERROR, payload: error });
    });
};
