import React, { useEffect, useRef } from 'react';
import styles from './Navigation.module.scss';
import cn from 'classnames';

const Navigation = ({
  optionsList,
  placeholder,
  handleSelectNavigation,
  isNavigationVisible,
  setIsNavigationVisible,
  changeBreadcrumbs,
  fieldName,
}) => {
  const dropdownRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef.current.contains(e.target)) {
        setIsNavigationVisible(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <div ref={dropdownRef} className={styles.select}>
      <div
        className={cn(`${styles.select__dropdown}`,
          { [`${styles.select__dropdown_active}`]: isNavigationVisible }
        )}
        onClick={() => {
          setIsNavigationVisible(!isNavigationVisible);
        }}
      >
        {placeholder}
      </div>
      <ul
        className={cn(`${styles.select__dropdownList}`,
          { [`${styles.select__dropdownList_active}`]: isNavigationVisible }
        )}
      >
        {optionsList && optionsList.map((el, index) => (
          <li
            className={cn(`${styles.select__dropdownItem}`,
              { [`${styles.select__dropdownItem_active}`]: el.name === placeholder }
            )}
            key={el.id === undefined ? index : el.id}
            onClick={() => {
              handleSelectNavigation(el, fieldName, index)
              // changeBreadcrumbs(el);
            }}
          >
            <span>{el.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Navigation;
