import React, { useState, useCallback, useEffect } from 'react';
import styles from './TextInput.module.scss';
import cn from 'classnames';

const TextInput = ({
  part,
  setValue,
  resetValue,
  isError,
  resetError,
  backgroundColor,
  maxLength = null,
}) => {
  const debounce = require('lodash.debounce');
  const [inputValue, setInputValue] = useState(part.value || '');
  const changeInputValue = useCallback(debounce((value) => {
    setValue(part.id, value, 'value', part.type);
  }, 300), []);

  const handleChangeInput = ({ target: { value } }) => {
    if (!maxLength || value.length <= maxLength) {
      setInputValue(value);
      changeInputValue(value);
    }
  };

  useEffect(() => {
    resetValue && setInputValue('');
  }, [resetValue]);

  return (
    <input
      type="text"
      className={cn(`${styles.textInput}`,
        { [`${styles.error}`]: isError },
        { [`${styles.textInput__white}`]: backgroundColor }
      )}
      value={inputValue}
      onChange={(e) => handleChangeInput(e)}
      onFocus={() => resetError(part.id)}
    />
  );
}

export default TextInput;
