import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import styles from './ListeningWordOrder.module.scss';
import { BASE_URL, RATING_MAX, RATING_MIN } from '../../../../constants/globalConstants';
import FKMediaUploader from '../../../../components/FKMediaUploader/FKMediaUploader';
import FKInput from '../../../../components/FKInput/FKInput';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import AddButton from '../../../../components/AddButton/AddButton';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import { editPopupOpen, popupOpen } from '../../../../store/actions';
import EditPopupButtons from '../../../../components/EditPopupButtons/EditPopupButtons';

const ListeningWordOrder = ({ question, handleClosePopup = () => {} }) => {
  const dispatch = useDispatch();
  const isEditPage = Boolean(question);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      audio: Yup.mixed().when('isEditPage', {
        is: false,
        then: Yup.mixed().required(),
      }),
      sentences: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(),
        }),
      ),
      rating: Yup.number()
        .min(RATING_MIN)
        .max(RATING_MAX)
        .required(),
    });
  }, []);

  const initialState = {
    isEditPage: false,
    sentences: [
      { name: '', id: uuid() },
      { name: '', id: uuid() },
      { name: '', id: uuid() },
    ],
    rating: '',
  };

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const requestSentences = values.sentences.map(({ name }) => name.trim());

      const requestData = {
        sentences: requestSentences,
        answers: [{}],
        rating: values.rating,
        file: values.audio,
      };

      if (isEditPage) {
        const { id } = question;

        dispatch(editPopupOpen(id, requestData));
      } else {
        dispatch(popupOpen(requestData, resetForm));
      }

      setSubmitting(false);
    },
    [dispatch, isEditPage, question],
  );

  return (
    <Formik initialValues={question || initialState} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>
      {({ values }) => {
        const fileUrl = `${BASE_URL}/file/${question?.fileId}`;
        const preview = values.audio && URL.createObjectURL(values.audio);
        const audioPreview = isEditPage ? fileUrl : preview;

        return (
          <Form>
            {!isEditPage && (
              <section className={styles.questionWrapper}>
                <h3 className={styles.title}>File (audio):</h3>
                <Field name='audio' accept='audio/*' maxSize={100000000} component={FKMediaUploader} width='50%' />
              </section>
            )}
            <section className={styles.questionWrapper}>
              <div className={styles.questionTitleContainer}>
                <h3 className={styles.title}>Question</h3>
              </div>
              <FieldArray name='sentences'>
                {arrayHelpers =>
                  values.sentences.map((question, i) => {
                    const disabledAddBtn = values.sentences.length >= 20;
                    const disabledDeleteBtn = values.sentences.length <= 3;
                    const showAddBtn = i === values.sentences.length - 1;

                    const handleAddQuestion = () => {
                      arrayHelpers.push({ name: '', id: uuid() });
                    };

                    return (
                      <div key={question.id} className={styles.questionContainer}>
                        <Field name={`sentences.${i}.name`} component={FKInput} placeholder='Enter your question' width='60%' />
                        <DeleteButton disabled={disabledDeleteBtn} onClick={() => arrayHelpers.remove(i)} />
                        {showAddBtn && <AddButton disabled={disabledAddBtn} onClick={handleAddQuestion} />}
                      </div>
                    );
                  })
                }
              </FieldArray>
            </section>
            <section className={styles.ratingContainer}>
              <h3 className={styles.title}>Approximate rating</h3>
              <Field name='rating' type='number' component={FKInput} placeholder='Enter question rating' width='25%' />
            </section>
            <section>
              <h3 className={styles.title}>Preview</h3>
              <div className={styles.previewContainer}>
                <div className={styles.audioContainer}>
                  <audio controls src={audioPreview}>
                    <p>
                      Your browser does not support HTML5 audio.
                      <a href={audioPreview}>{values.audio?.name}</a>
                    </p>
                  </audio>
                </div>
                <div className={styles.preview}>
                  {values.sentences.map(({ name, id }) => (
                    <div className={styles.solutionAnswer} key={id}>
                      {name}
                    </div>
                  ))}
                </div>
              </div>
            </section>
            {isEditPage ? <EditPopupButtons handleClose={handleClosePopup} /> : <SaveButton type='submit' />}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ListeningWordOrder;
